import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {Button, Radio, Form, Input} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import './style.css';
import {getKeyPrivateRoute} from '../../../components/PrivateRoute';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';

export default function Example(props) {
    
    let { id } = useParams();
    const [title, setTitle] = useState('');
    const [loginAction, setLoginAction] = useState(false);

    const onFinish = (values) => {
        //console.log('Success:', values);

        const cls = {
            "collections": 
                {
                    "document": {
                        "name": values.title,
                        "description": values.description === "undefined" ? "" : values.description,
                        "schema": {}
                    }
                }
            
        };

        const headers = {
            "auth-token": getKeyPrivateRoute().token
        }

        axios.post(process.env.REACT_APP_END_POINT + "/project/"+id+"/collection/add", cls, { headers }).then((response) => {
            message.open({
                type: 'success',
                content: "Project created successfully!",
            });
            
            props.success(response.data);
            props.setOpen();
            
        }).catch((err) => { console.log(err);
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else
            if(err.response.status === 400){
                message.open({
                    type: 'error',
                    content: err.response.data.message,
                });
            }else if(err.response.status === 401){
                message.open({
                    type: 'error',
                    content: "Connection refused",
                });
            }

            setTimeout(() => {
                setLoginAction(false);
            }, 600);
        });

        
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = val => {
        setTitle(val);
    }

    return (
        <Transition.Root show={props.show} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >

                    <div className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-x-0"
                                        leaveTo="translate-x-full"
                                    >
                                        <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                                        

                                        {/* Modal Window */}
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                            <div className="px-4 sm:px-6 bg-gray-100 py-6 flex">
                                                <div className="w-full">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                        Add Model
                                                    </Dialog.Title>
                                                    <p className='text-sm text-gray-600 mt-1'>Aggiungi un nuovo modello </p>
                                                </div>
                                                <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-in-out duration-500"
                                                        enterFrom="opacity-0"
                                                        enterTo="opacity-100"
                                                        leave="ease-in-out duration-500"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <div className="flex center">
                                                            <button
                                                                type="button"
                                                                className="rounded-md text-gray-400 hover:text-gray-500  focus:outline-none"
                                                                onClick={props.setOpen}
                                                            >
                                                                <span className="sr-only">Close panel</span>
                                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    </Transition.Child>
                                                </div>
                                                <div className="relative mt-0 flex-1 px-0 sm:px-0">
                                                    
                                                    <div className="">
                                                        <div className="">
                                                            <Form
                                                                onFinish={onFinish}
                                                                layout="vertical"
                                                                size="large"
                                                                requiredMark={'optional'}
                                                                autoComplete="off"
                                                                className='mt-10 mx-6'
                                                            >
                                                                <Form.Item
                                                                    label="Display name" name="title" rules={[
                                                                        {
                                                                          required: true,
                                                                          message: 'Please insert a name!',
                                                                        },
                                                                      ]} tooltip="Name that will be displayed in Hygraph" onChange={onChange}
                                                                >
                                                                    <Input className='rounded-[4px] focus:border-indigo-600 hover:border-indigo-200' />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    label="Description"
                                                                    name="description"
                                                                    tooltip={{
                                                                      title: 'Displays a hint for content editors and API users',
                                                                      icon: <InfoCircleOutlined />,
                                                                    }}
                                                                >
                                                                    <Input.TextArea showCount maxLength={100} />
                                                                </Form.Item>

                                                                <Form.Item className='mt-10'>
                                                                    <div className="flex justify-between">
                                                                        <Button type="text" onClick={props.setOpen}>
                                                                            Cancel
                                                                        </Button>
                                                                        
                                                                        <Button type="primary" htmlType="submit" disabled={title.length <= 0} className="bg-indigo-600 hover:!bg-indigo-800 disabled:hover:bg-gray-800" loading={loginAction}>
                                                                            Add model
                                                                        </Button>
                                                                    </div>
                                                                </Form.Item>
                                                            </Form>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

import React from "react";
import { FirstColumn, Main } from '../../../components/colums';
import { Table, message, Upload, Popconfirm ,ConfigProvider} from 'antd';
import './style.css'
import {Link} from "react-router-dom";
import SliderAddUsers from '../../../components/sliders-columns/settings-add-users/slider-users';
import { withRouter } from '../../../components/withRouter';
import {getKeyPrivateRoute} from '../../../components/PrivateRoute';
import axios from 'axios';

import {
    UsergroupAddOutlined
  } from '@ant-design/icons';



class Index extends React.Component{

    constructor(props){
        super(props);

        const {params} = this.props;

        const this_ = this;

        this.state = {
            token: getKeyPrivateRoute().token,
            removeLoading: false,
            modalShow: false,
            sxCls: {
                title: "Settings",
                collapses: [
                    {
                        title: "General",
                        id: null,
                        menu: [
                            {
                                title: "Project",
                                link: "/project/"+params.id+"/settings/project"
                            },
                            {
                                title: "BackUp",
                                link: "/project/"+params.id+"/settings/backup",
                            },
                            {
                                title: "Tokens Public Api",
                                link: "/project/"+params.id+"/settings/project/api-tokens",
                                active: false
                            }
                        ]
                    },
                    {
                        title: "Access",
                        id: null,
                        menu: [
                            {
                                title: "Roles & Permissions",
                                link: "/project/"+params.id+"/settings/permissions",
                            },{
                                title: "Team members",
                                link: "/project/"+params.id+"/settings/teams",
                                active: true
                            }
                        ]
                    }
                ]
            },
            dataSource: [],
            columns : [
                {
                  title: 'User',
                  dataIndex: 'user',
                  key: 'user',
                  render: (t) => (<div className="flex items-center gap-x-3">
                                    <img className="h-8 w-8 rounded" src={t.avatar} alt="" />
                                    <div>
                                        <h3 className="text-sm  tracking-tight text-gray-900">{t.name} {t.surname}</h3>
                                        <p className="text-xs text-gray-600">{t.email}</p>
                                    </div>
                                </div>)
                },
                {
                  title: 'Role',
                  dataIndex: 'role',
                  key: 'role',
                  render: (t) => (<Link to={"../settings/permissions/" + t.key} className="">
                                        {t.name}
                                    </Link>)
                },
                {
                    title: ' ',
                    dataIndex: 'action',
                    key: 'action',
                    render: (_, record) =>
                        this.state.dataSource.length >= 1 ? (
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: 'rgb(99 102 241)',
                                    },
                                }}
                            >
                                 <Popconfirm title="Sure to delete?" onConfirm={() => this.removeMember(record)}>
                                    <a>X</a>
                                </Popconfirm>
                            </ConfigProvider>
                           
                        ) : null
                  }
            ]
        }
    }

    componentDidMount(){
        const {params} = this.props;

        const headers = {
            "auth-token": this.state.token
        }

        let r = []

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+params.id + "/team/invites", { headers }).then((response) => {
            
            response.data.map((data, i) => {
                r[i] = {
                    key: data._id,
                    action: 0,
                    user: {
                        name: "Invite",
                        surname: "Pending",
                        avatar: "https://xsgames.co/randomusers/assets/avatars/pixel/47.jpg",
                        email: data.email
                    },
                    role: {name:data.roles.name, key: data.roles._id},
                }
                this.setState({dataSource: r});
            });
            
        }).catch((err) => {
            console.log(err);
            message.open({
                type: 'error',
                content: "Errore: 500!",
            });
        });

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+params.id + "/teams", { headers }).then((response) => {
            response.data.map((data, i) => {
                r[i] = {
                    key: data._id,
                    action: 1,
                    user: {
                        name: data.name,
                        surname: data.surname,
                        avatar: "https://xsgames.co/randomusers/assets/avatars/pixel/47.jpg",
                        email: data.email
                    },
                    role: {name:data.role.name, key: data.role._id},
                }
                this.setState({dataSource: r});
            });
        }).catch((err) => {
            console.log(err);
            message.open({
                type: 'error',
                content: "Errore: 500!",
            });
        });
    }

    removeMember = (value) => {
        if(value === null) return;


        const {params} = this.props;

        const headers = {
            "auth-token": this.state.token
        }
        

        if(value.action === 0){
            axios.delete(process.env.REACT_APP_END_POINT + "/project/"+params.id + "/team/invite/"+value.key, { headers }).then((response) => {
                const newData = this.state.dataSource.filter((item) => item.key !== value.key);
                this.setState({dataSource: newData})
            }).catch((err) => {
                console.log(err);
                message.open({
                    type: 'error',
                    content: "Errore: 500!",
                });
            });
        }else{
            axios.delete(process.env.REACT_APP_END_POINT + "/project/"+params.id + "/team/"+value.key, { headers }).then((response) => {
                const newData = this.state.dataSource.filter((item) => item.key !== value.key);
                this.setState({dataSource: newData})
            }).catch((err) => {
                console.log(err);
                message.open({
                    type: 'error',
                    content: "Errore: 500!",
                });
            });
        }


        //console.log(newArray);
    
    };

    onFinish = (value) => {
        console.log(value);
    };

    enterLoading = (index) => {
        this.setState({
            removeLoading: true
        });
        
    
        setTimeout(() => {
            this.setState({
                removeLoading: false
            });
        }, 6000);
    };

    setOpen = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    saveSlider = (e) => {


        const dataSource = this.state.dataSource;
        dataSource[dataSource.length] = {
            key: dataSource.length +1,
            user: e.user,
            role: e.role,
            action: dataSource.length +1
        }

        this.setState(dataSource)
    }

    render(){
        const { sxCls, columns,dataSource } = this.state;
        const props = {
            beforeUpload: (file) => {
              const isPNG = file.type === 'image/png';
              if (!isPNG) {
                message.error(`${file.name} is not a png file`);
              }
              return isPNG || Upload.LIST_IGNORE;
            },
            onChange: (info) => {
              console.log(info.fileList);
            },
          };

        
        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500 ">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1 overflow-y-auto scrollbar">
                    {/*<!-- Right column content -->*/}
                    <div className="first">
                       
                        <div className="px-10 py-10">
                            <h1 className='text-2xl font-bold my-2 mr-2'>Team members</h1>
                            <p className="text-gray-500">Get an overview and control what your team members can do in Hygraph.</p>


                            <div className="mt-10">
                                <div className="flex mb-3">
                                    <p className="uppercase text-[0.875rem] tracking-wider text-gray-600 flex-none">Active Users ({dataSource.length}/3)</p>
                                    <div className="grow"></div>
                                    {
                                        dataSource.length >= 3 ?
                                            <Link disabled className="disabled flex-none text-xs text-indigo-600/50 rounded py-1 px-2"><UsergroupAddOutlined className="relative top-[-2px] mr-1 text-sm " />Add member team</Link>
                                        :
                                            <Link onClick={this.setOpen}  className="flex-none text-xs text-indigo-600 hover:bg-indigo-50 rounded py-1 px-2"><UsergroupAddOutlined className="relative top-[-2px] mr-1 text-sm " />Add member team</Link>

                                    }
                                </div>
                                <Table className="border-2 border-gray-300 rounded table-b" size="small" pagination={false} dataSource={[...dataSource]} columns={columns} />
                            </div>

                        </div>
                        
                    </div>
                </div>

                <SliderAddUsers show={this.state.modalShow} setOpen={this.setOpen} saveSlider={this.saveSlider} projectID={this.props.params.id}/>
            </>
        );
    }
}

export default withRouter(Index);
import { useParams, useNavigate, useOutletContext  } from 'react-router-dom';
import React from 'react';

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();
        
        return (
            <Component
                params={useParams()}
                navigate={navigate}
                useOutletContext={useOutletContext()}
                {...props}
            />
        );
    };
    
    return Wrapper;
};
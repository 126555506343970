import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';

import './input.css';
import reportWebVitals from './reportWebVitals';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';

import Login from './pages/Auth/Login';
import Invite from './pages/Invite';
import RestorePwd from './pages/Auth/ForgPass';
import RestorePwdC from './pages/Auth/ConfermeResetPassword';
import Content from './pages/Content/Index';
import ContentPrev from './pages/Content/ContentPrev';
import Schema from './pages/Schema/Index';
import SchemaPrev from './pages/Schema/SchemaPrev';
import Assets from './pages/Assets/Index';
import Projects from './pages/Projects/Index';
import AddProject from './pages/AddProject/Index';
import Home from './pages/Home/Index';
import Settings from './pages/Settings/Index';
import Teams from './pages/Settings/Teams/Index';
import Roles from './pages/Settings/Roles/Index';
import Tokens from './pages/Settings/Tokens/Index';
import Permissions from './pages/Settings/Permissions/Index';
import Account from './pages/Account/Index';
import AddContent from './pages/Content/AddContent';
import Err4040 from './components/errorPages/404';
import EditContent from './pages/Content/EditContent';
import Playground from './pages/Content/Playground';
import BackUp from './pages/Settings/BackUp/Index';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
        <Routes>
			<Route exact path="/" element={<Login />}/>
			<Route exact path="/invite/:id" element={<Invite />}/>
			<Route path="/restore-password" element={<RestorePwd />}/>
			<Route path="/restore-password-conferme/:id" element={<RestorePwdC />}/>
			<Route exact path='/' element={<PrivateRoute/>}>
				<Route path="/project/:id" element={<Layout />}>
					<Route index element={<Home />}/>
					<Route path="/project/:id/schema" element={<SchemaPrev />} />
					<Route path="/project/:id/schema/model/:model" element={<Schema />} />
					<Route path="/project/:id/content" element={<ContentPrev />} />
					<Route path="/project/:id/content/:table" element={<Content />} />
					<Route path="/project/:id/content/:table/new" element={<AddContent />} />
					<Route path="/project/:id/content/:table/edit/:row" element={<EditContent />} />
					<Route path="/project/:id/assets" element={<Assets />} />
					<Route path="/project/:id/master/playground" element={<Playground />} />
					<Route path="/project/:id/settings/project" element={<Settings />} />
					<Route path="/project/:id/settings/backup" element={<BackUp />} />
					<Route path="/project/:id/settings/project/api-tokens" element={<Tokens />} />
					<Route path="/project/:id/settings/permissions" element={<Roles />} />
					<Route path="/project/:id/settings/permissions/:pID" element={<Permissions />} />
					<Route path="/project/:id/settings/teams" element={<Teams />} />
					<Route path="*" element={<Err4040 />} />
				</Route>
				<Route path="/projects" element={<Projects />}/>
				<Route path="/project/add" element={<AddProject />}/>
				<Route path="/account" element={<Account />}/>
			</Route>
			
			<Route path="*" element={<>Errore 404</>}/>
		</Routes>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

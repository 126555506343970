import { Button, Result } from 'antd';
import React from 'react';

const App = () => (
    <div className="flex justify-start items-center w-full">
        <Result
            status="404"
            title="404"
            className='w-full'
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" className='bg-indigo-700 hover:!bg-indigo-800'>Back Home</Button>}
        />
    </div>
);
export default App;
import React, { useState } from 'react';
import { InboxOutlined, PlusOutlined} from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import axios from 'axios';


const { Dragger } = Upload


const App = (props) => {
    const [messageApi, contextHolder] = message.useMessage();

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const handleUpload = async () => {
        const key = 'updatable';
        const {params, token} = props;
        const headers = {
            "auth-token": token,
            'Content-Type': 'multipart/form-data'
        }
        
        setUploading(true);
        
        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
        });

        let count = 1;
        fileList.forEach(async (file, i) => {
            const formData = new FormData();
            formData.append('file', file);

            if(formData == null){
                message.open({
                    key,
                    type: 'error',
                    content: "Non ci sono file per il backUp delle collection",
                });
                return;
            }
            
            await axios.put(process.env.REACT_APP_END_POINT + "../../../project/"+ params.id +"/backupdb/upload", formData,{ headers } ).then((response) => {
                //console.log(response.data);
                if(response.data.size > 0){
                    if(count === fileList.length){
                        message.open({
                            key,
                            type: 'success',
                            content: "Caricamento effettuato!",
                        });
                        setUploading(false);

                        setFileList([]);
                    }
                    count++;
                }
            }).catch((err) => {
                if(err.code === "ERR_NETWORK"){
                    message.open({
                        type: 'error',
                        content: "Nessuna connessione con il server!",
                    });
                }else{
    
                    message.open({
                        type: 'error',
                        content: err.response,
                    });
                }
                
            });
            
        });
        // You can use any AJAX library you like
        
       

        
    };

    let files = [];
    const uploader = {
        name: 'file',
        multiple: true,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            
            const isPNG = file.type === 'application/json';
            if (!isPNG) {
                message.error(`${file.name} is not a json file`);
                return;
            }


            /*if(!file.name.match(/^[0-9a-f]{24}\.[0-9a-z_]+\.json$/)){
                message.error(`${file.name} is not a mongodb file`);
                return;
            }*/

            //setFileList([...fileList, file]);
            files.push(file);
            setFileList(files);
            
            return false;
        },
        fileList,
    };

    

    return (
            <>
            {contextHolder}
                <div className="mt-10 bg-white shadow-md mb-2 rounded item py-6 px-8 border border-grey-400 max-w-2xl">
                    <Dragger {...uploader}  >
                        <p className="ant-upload-drag-icon ">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Clicca o trascina qui per caricare</p>
                        <p className="ant-upload-hint">
                            Ricorda l'uploader accetta solo file ti tipo json mongodb
                        </p>
                    </Dragger>
                </div>

                <Button
                    type="primary"
                    icon={<PlusOutlined  className="relative top-[-4px]" />}
                    
                    loading={uploading}
                    disabled={fileList.length === 0 }
                    className="mt-6 bg-orange-600 hover:!bg-orange-700"
                    //onClick={() => this.enterLoading(1)}
                    onClick={handleUpload}
                    >
                    {uploading ? 'Caricamento' : 'Carica BackUp'}
                </Button>
            </>
    );
};
export default App;
import React from 'react';
import { Collapse, Tooltip  } from 'antd';
import { Link } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';
import { CaretDoubleRight, CaretDoubleLeft } from '@phosphor-icons/react';
import  './style.css';
import {getKeyPrivateRoute, removeKeyPrivateRoute} from '../../PrivateRoute';

class FirstColumn extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            token: getKeyPrivateRoute().token,
            user: getKeyPrivateRoute().user,
            close: false
        }
    }

    componentDidMount() {
        
    }

    actionColumnStat = () => {
        this.setState({
            close: !this.state.close
        });
        this.props.actionFColumn(!this.state.close)
    }   

    render(){
        const {title, menu_list} = this.props;
        const { Panel } = Collapse;
        const {close} = this.state;


        return (
            <div className='relative '>
                <a onClick={this.actionColumnStat} className='z-50 bg-white border border-gray-300 w-50 h-50 inline-block rounded-2xl p-1 absolute -right-3 cursor-pointer hover:bg-gray-100 top-12'>
                    <Tooltip placement="bottom" title={"Collapse sidebar"} arrow={true}>
                        {
                            close ? <CaretDoubleRight color="#000" size={15} /> : <CaretDoubleLeft color="#000" size={15} />
                        }
                        
                    </Tooltip>
                </a>
                <div className={[close ? 'hidden' : "block", 'w-64', 'clms-f'].join(" ")}>
                    <div className="p-3 py-2 px-4">
                        <h1 className='text-2xl font-bold my-2'>{title}</h1>
                    </div>
                    <div className="p-3 px-4 py-5 overflow-auto border-box h-[calc(100vh_-_65px)] scrollbar">
                        <Collapse 
                            defaultActiveKey={['0', '1', '2', '3']} 
                            ghost
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} /> }
                            className={"collapse_ord relative"}
                        >
                            {
                                
                                menu_list.map((menu, index) => {

                                    return (
                                        <Panel header={<><span className='tracking-widest text-xs'>{menu.title}</span> </>} key={index} className="relative my-2">
                                            {
                                                menu.add === true ?
                                                    <Link to="" onClick={menu.addAction} className='absolute top-0 right-6 text-indigo-500 text-sx'>+ Add</Link>
                                                : ""
                                            }
                                            <ul>
                                                {
                                                    typeof menu.menu == "string" ? 
                                                        <p className='text-gray-500'>{menu.menu}</p>
                                                    :
                                                    menu.menu.length === 0 ? 
                                                        <p className='text-gray-500'>Add a models for construct your project.</p>
                                                    :
                                                    menu.menu.map((item, index2) => {
                                                        

                                                        return <li key={index2}>
                                                                    <Link to={item.link} className={[" my-1 font-normal w-full block py-1 px-2 rounded hover:bg-gray-400/[.1] focus:bg-indigo-500/[.1] hover:text-inherit", item.active === true ? "bg-indigo-400/[.1] text-indigo-600" : ""].join(" ")}>
                                                                        {item.title}
                                                                    </Link>
                                                                </li>
                                                    })
                                                }
                                            </ul>
                                        </Panel>
                                    );
                                })
                            }
                        </Collapse>
                            
                    </div>
                </div>
            </div>
        )
    }

}

export default FirstColumn;
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { List,Checkbox, message } from 'antd';
import axios from 'axios';
import {getKeyPrivateRoute} from '../../PrivateRoute';


export default function Example(props) {
    const [messageApi, contextHolder] = message.useMessage();

  //const [open, setOpen] = useState(props.show)
    const dataCheck = [
        "Change the name, picture and description of a project.",
        "Access Backup project.",
        "Create, edit, remove roles.",
        "Assign a role to a user.",
        "Access on drive project.",
        "Add, edit, delete Schema project.",
        "Add, edit, delete Content project.",
        "Access on Play Test Api.",
        "Invite a user into an existing project.",
        "Remove a user from an existing project.",
    ]

    const dataCheckName = [
        {"project_settings": true},
        {"project_backup": true},
        {"project_roles": true},
        {"project_assign_user_roles": true},
        {"project_access_dive": true},
        {"project_action_schema": true},
        {"project_action_content": true},
        {"project_access_test_api": true},
        {"project_invite_users": true},
        {"project_remove_user": true},
    ]

    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [link, setLink] = useState("");
    const [roles, setRoles] = useState([]);

    const onChange = (e) => {
        const id = e.target.name;
        //const checked = e.target
        let name = Object.keys(dataCheckName[id]);
        let check = Object.values(dataCheckName[id]);
        //e.target.checked
        if(check){
            //dataCheckName[id] = {[name]: !check}
            roles.push(name[0])
            setRoles(roles);
        }
        
    }

    const onSubmit = (e) => {
        e.preventDefault();
        
        if(name.length <= 0){
            messageApi.open({
                type: 'error',
                content: 'Ricorda di inserire un Nome per i permessi!',
            });
            return;
        }
        

        const headers = {
            "auth-token": getKeyPrivateRoute().token
        }

        
        axios.post(process.env.REACT_APP_END_POINT + "/project/"+ props.project +"/roles/add", {
            name: name,
            desc: desc,
            roles: roles
        },{ headers }).then((response) => {
            console.log(response.data);

            props.saveSlider({
                name: name,
                desc: desc,
                link: link,
                roles: roles
            });
    
            props.setOpen();
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });

        
    }

    const handleInputChange = (e) => {
        //const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        const name = e.currentTarget.name;

        if(name == "name"){
            setName(value);
        }else if(name == "desc"){
            setDesc(value);
        }

    }

    

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {contextHolder}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  

                  {/* Modal Window */}
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-6 py-6 flex">
                      <div className="w-full">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Add Roles
                        </Dialog.Title>
                      </div>
                      <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex center">
                                <button
                                    type="button"
                                    className="rounded-md text-gray-400 hover:text-gray-500  focus:outline-none"
                                    onClick={props.setOpen}
                                >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </Transition.Child>
                    </div>
                    <div className="relative mt-0 flex-1 px-0 sm:px-0">
                        
                        <div className="">
                            <div className="">
                                <form onSubmit={onSubmit}>
                                    <div className="sm:overflow-hidden sm:rounded-md">
                                        <div className="space-y-6 bg-white ">
                                            <div className="grid">
                                                <div className="px-4 py-5 sm:p-6 col-span-3 sm:col-span-2 grid grid-cols-6 grid-flow-row-dense content-center items-center">
                                                    <label htmlFor="company-website" className="flex justify-start items-center h-full col-span-1 block text-sm font-medium leading-6 text-gray-900">
                                                        Name
                                                    </label>
                                                    <div className="mt-0 flex rounded-md shadow-sm col-span-5">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            autoComplete="given-name"
                                                            onChange={handleInputChange}
                                                            className=" block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="px-4 py-5 sm:p-6 col-span-3 sm:col-span-2 grid grid-cols-6 grid-flow-row-dense content-center items-center">
                                                    <label htmlFor="company-website" className="flex justify-start items-center h-full col-span-1 block text-sm font-medium leading-6 text-gray-900">
                                                        Desc.
                                                    </label>
                                                    <div className="mt-0 flex rounded-md shadow-sm col-span-5">
                                                        <textarea
                                                            id="desc"
                                                            name="desc"
                                                            rows={3}
                                                            onChange={handleInputChange}
                                                            className="px-2 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:py-1.5 sm:text-sm sm:leading-6"
                                                            placeholder="Write a Descriptions"
                                                            defaultValue={''}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-4">
                                                    <h3>List permissions</h3>

                                                    <List
                                            
                                                        dataSource={dataCheck}
                                                        renderItem={(item, index) => {

                                                            return (
                                                                <List.Item className="!px-4" key={index}>
                                                                    <Checkbox onChange={onChange} name={index}>{item}</Checkbox>
                                                                </List.Item>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                
                                            </div>

                                            </div>
                                            <div className=" px-4 py-3 text-right sm:px-6">
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import {  Table } from 'antd';

import React, { useState } from 'react';

function useWindowDimensions(actionFColumn) {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [afc, setAfc] = React.useState(actionFColumn);
    const [height, setHeight] = React.useState(window.innerHeight);

    const updateWidthAndHeight = () => {
        if(document.querySelector('.asidal-f') && document.querySelector('.clms-f')) {
            setWidth(window.innerWidth - document.querySelector('.clms-f').offsetWidth - (document.querySelector('.asidal-f').offsetWidth +5));
            setHeight(window.innerHeight - 260.19);

            document.querySelector(".ant-table-body").style.height = document.body.offsetHeight - 182.8 + "px";
        }
        
    };

    React.useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        

        if(afc != actionFColumn){
            setAfc(actionFColumn);
            updateWidthAndHeight();
        }
        updateWidthAndHeight();

        //setWidth(window.innerWidth - document.querySelector('.clms-f').offsetWidth - (document.querySelector('.asidal-f').offsetWidth +5));
        //setHeight(window.innerHeight - 260.19);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    return {
        width,
        height,
    }
}


const App = (props) => {
    const [selectionType, setSelectionType] = useState('checkbox');

    const { data, columns,loading} = props;

        
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            props.hasSelected(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    
    const { width, height } = useWindowDimensions(props.actionFColumn)
    //console.log(width)
    return (
        <div>

            <Table
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    position: ['bottomCenter'],
                }}
                rowKey={(record) => record.ID} 
                /*className='w-80'*/
                onChange={onChange}
                style={{width: width+ "px", height: height + "px"}}
                size="small" 
                sticky
                scroll={{
                    x: width,
                    y: height
                }}
            />
        </div>
    );
};

export default App;
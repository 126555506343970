import React from "react";
import { Space, Tag, Image } from 'antd';
import {Link} from "react-router-dom";
import { FirstColumn, Main } from '../../components/colums';
import DropdownButton from "../../components/dropdown-button";
import Table from "../../components/content/table/table";
import { Form, Input, Button } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';


class Index extends React.Component{

    constructor(props){
        super(props);


        this.state = {
            sxCls: {
                title: "Assets",
                collapses: [
                    {
                        title: "Custom Views",
                        id: null,
                        add: false,
                        menu: 'Custom views help you add customized views by configuring table columns and filtering out entries for quick access.'
                    },
                    {
                        title: "Default Views",
                        id: null,
                        menu: [
                            {
                                title: "Assets",
                                link: ""
                            }
                        ]
                    }
                ]
            },
            columns: [
                {
                    title: '',
                    key: 'action',
                    width: 60,
                    align: 'center',
                    render: (_, record) => (
                        <Space size="middle">
                            <Link className='hover:text-indigo-500 text-indigo-500 hover:bg-indigo-500/10 flex align-center p-2 rounded'><span className="material-symbols-outlined text-[1rem]">edit</span></Link>
                        </Space>
                    ),
                }
                ,
                {
                    title: 'Previe',
                    key: 'preview',
                    dataIndex: 'preview',
                    width: 120,
                    render: (text) => <Image width={59} height={59} src={text} /> 
                }
                ,
                {
                    title: 'Stages',
                    key: 'stages',
                    dataIndex: 'stages',
                    width: 120,
                    render: (_, { tags }) => {
                        
                        return (
                            <Tag color={_.color} key={_}>
                                {_.text}
                            </Tag>
                                    
                        )
                    }
                },
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    width: 120,
                    render: (text) => <Tag color={'default'} >{text}</Tag>,
            
                },
                {
                    title: 'Created At',
                    dataIndex: 'createdat',
                    key: 'createdat',
                    width: 120,
                    render: (text) => <span className='text-sm'>{text}</span>,
                    /*sorter: {
                        compare: (a, b) => a.age - b.age,
                        multiple: 3,
                    },*/
                },
                {
                    title: 'Created By',
                    dataIndex: 'createdby',
                    key: 'createdby',
                    width: 120,
                    render: (text) => <span className='text-sm'>{text}</span>,
                }
                
            ],
            dataSource: [
                {
                    key: '1',
                    id: 'ckadqdbhk00go0148zzxh4bbq',
                    preview: 'https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/o8jx816S0WJAPzusQs7V',
                    createdat: '19 ago 2020, 15:43 CEST',
                    createdby: '-',
                    stages: {
                        type: 'public',
                        text: 'Published',
                        icon: null,
                        color: 'green'
                    },
                },
                {
                    key: '2',
                    id: 'ckadqdbhk00go0148zzxh4bbq',
                    preview: 'https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/o8jx816S0WJAPzusQs7V',
                    createdat: '19 ago 2020, 15:43 CEST',
                    stages: {
                        type: 'public',
                        text: 'Published',
                        icon: null,
                        color: 'green'
                    },
                },
                {
                    key: '3',
                    id: 'ckadqdbhk00go0148zzxh4bbq',
                    preview: 'https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/o8jx816S0WJAPzusQs7V',
                    createdby: '-',
                    stages: {
                        type: 'public',
                        text: 'Published',
                        icon: null,
                        color: 'geekblue'
                    },
                },
            ],
            filterTable: null,
            hasSelected: []
        }
    }

    render(){
        const { sxCls, filterTable, dataSource, hasSelected,columns } = this.state;

        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1">
                    {/*<!-- Right column content -->*/}
                    <div className="first">
                        <div className="p-3 py-2 px-4 flex justify-start items-center border-b border-grey-500">
                            <div className="flex justify-start items-center">
                                <h1 className='text-xl font-medium my-2 mr-2'>All</h1>
                                <DropdownButton 
                                    className="" 
                                    buttonClassName="inline-flex w-full justify-center rounded-md ml-2 px-1 py-1 text-default font-medium text-gray-500 hover:bg-gray-100 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" 
                                    dropdownClassName="z-50 absolute right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    childrenButton={<span className="material-symbols-outlined">more_horiz</span>} 
                                    dropList={{}}
                                    chevron={false} 
                                />
                            </div>
                            <div className="grow"></div>
                            <div className="flex-none ">
                                <Button type="primary" className="bg-indigo-500 right" icon={<PlusOutlined  className="relative top-[-3px]" />}>Aggiungi</Button>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-grey-500">
                                <Form
                                    
                                    name="normal_login"
                                    className="login-form flex items-center"
                                    initialValues={{ remember: true }}
                                    >
                                    
                                    <Form.Item
                                        name="name"
                                        className="mx-3 my-2 "
                                    >
                                        <Input 
                                            name="name" 
                                            prefix={<SearchOutlined  className="site-form-item-icon" />}  
                                            placeholder="Nome progetto" 
                                            onChange={e => {

                                                const { dataSource } = this.state;

                                                const filterTable = dataSource.filter(o =>
                                                Object.keys(o).some(k =>
                                                    String(o[k])
                                                    .toLowerCase()
                                                    .includes(e.target.value.toLowerCase())
                                                )
                                                );

                                                this.setState({ filterTable }); 
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item className="mx-3 my-2 h-full">
                                        <Button type="primary" className="bg-indigo-500 hover:bg-indigo-600 h-full block">Remove filter</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="border-b border-grey-500">
                                <span className="mx-3 my-2 text-xs block relative text-gray-700">
                                    {hasSelected.length} entries selected
                                </span>
                            </div>
                            <Table data={filterTable == null ? dataSource : filterTable} hasSelected={this.hasSelected} columns={columns} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Index;
import React from 'react';
import axios from 'axios';
import {getKeyPrivateRoute} from '../../PrivateRoute';
import CardSchema from '../../schema/cardList';
import { withRouter } from '../../withRouter';
import DropdownButton from "../../dropdown-button";
import Table from "../../content/table/table";
import { Form, Input, Button, Tag, List, message, Switch, Select} from 'antd';
import { LeftOutlined, SaveOutlined,LoadingOutlined, CloseOutlined, CheckOutlined  } from '@ant-design/icons';
import {Link} from "react-router-dom";


class ContentMain extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            token: getKeyPrivateRoute().token,
            table: [],
            tableRes: [],
            save: false,
            columns: [],
            loginAction: false,
            objectids: []
        }

    }

    componentDidMount(){
        
        const {params } = this.props;
        const {fields, columns,tableRes} = this.state;
        
        const cp = JSON.parse(localStorage.getItem('cp'));

        if(cp){
            cp['name'] += " (Copy)";
    
            this.setState({
                tableRes: cp
            });

        }
    
        const headers = {
            "auth-token": this.state.token
        }
        let columns_ = [];

        const defK = ['ID', 'createAt', 'updateAt']

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collection/"+params.table, { headers }).then((response) => {
            this.setState({table: response.data});
            let n = 0;
            Object.keys(response.data.schema).map((dt, i) => {
                if(!defK.includes(dt)){
                    columns_[n] = {
                        title: dt,
                        description: null,
                        type: Object.values(response.data.schema)[i]
                    }
                    if(Object.values(response.data.schema)[i].type == "ObjectId"){
                        const objectids = this.state.objectids
                        objectids[Object.keys(response.data.schema)[i]] =[]
                         //Load user relationed collection
                         axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/content/"+Object.values(response.data.schema)[i].__, { headers }).then((res) => { 
                            
                            res.data.forEach((element, im)=> {
                                objectids[Object.keys(response.data.schema)[i]].push({ value: element._id, label: <div className='block grid grid-column'><span className="font-medium">{element[Object.values(response.data.schema)[i].__target]}</span></div> })
                            });
                            
                            this.setState(objectids);
                        }).catch((err) => { 
                            if(err.code === "ERR_NETWORK"){
                                message.open({
                                    type: 'error',
                                    content: "Nessuna connessione con il server!",
                                });
                            }else if(err.code === "ERR_BAD_REQUEST"){
                                message.open({
                                    type: 'error',
                                    content: "access error!",
                                });
                            }else{
                
                                message.open({
                                    type: 'error',
                                    content: err.response,
                                });
                            }
                            
                        });

                    }
                    n++;
                }
            })
            
            this.setState({columns: columns_});
        }).catch((err) => {  
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: "access error!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });

    }

    onFinish = (values) => {
        //console.log('Success:', values);
        
        const {params, navigate } = this.props;
        const {columns} = this.state;
        
        const headers = {
            "auth-token": this.state.token
        }
        let body = {
            "content": {
                "createAt": new Date(),
                "updateAt": null
            }
        };

        body.content= { ...body.content, ...values}

        axios.post(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/content/"+params.table, body, { headers }).then((response) => {
            
            navigate("/project/"+params.id+"/content/"+params.table);
        }).catch((err) => {  
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: "access error!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    };
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    onChange = val => {
        
    }

    
    render(){
        const {params } = this.props;
        const {table,save, columns,tableRes, objectids} = this.state;

        return (
            <div className="first">
                <Form
                    onFinish={this.onFinish}
                    layout="vertical"
                    requiredMark={'optional'}
                >
                <div className="p-3 py-2 px-4 flex justify-between items-center border-b border-grey-500">
                    <div className='flex justify-start items-center'>
                        <Link to={"/project/"+params.id+"/content/"+params.table}><Button type="text" shape="circle" className='!w-[50px] text-gray-600 font-medium mr-4' icon={<LeftOutlined className='font-medium relative top-[-4px] text-gray-400'/>} /></Link>
                        <h3 className='text-md font-normal my-2 mr-2'>New {table.name}</h3> 
                    </div>
                    <div className='flex justify-start items-center'>
                        <Button type="primary"htmlType="submit" shape="circle" className='bg-green-600 hover:!bg-green-700  font-medium mr-4 !px-4' icon={<SaveOutlined  className='font-medium relative top-[-4px]'/>}>Salva e pubblica</Button>
                        {save ? <LoadingOutlined className='text-gray-600' /> : <span className='block relative w-[16px]'></span>}
                    </div>
                </div>

                <div className='p-10 mt-3  overflow-auto scrollbar h-[calc(100vh_-_65px)] w-3/4'>
                    
                    <List
                        itemLayout="horizontal"
                        dataSource={columns}
                        renderItem={(item, index) => (
                        <List.Item className='!border-y-0 !py-2' key={index}>
                            <List.Item.Meta
                                className='border-l-2 border-gray-300 pl-4 '
                                description={<div> 
                                    {
                                        item.type.type == "boolean" ? 
                                            <Form.Item
                                                label={item.title} name={item.title} rules={[
                                                    {
                                                        required: false,
                                                        message: 'Please insert a ' + item.title,
                                                    },
                                                    ]} tooltip={item.description} onChange={this.onChange} initialValue={tableRes[item.title]}
                                            >
                                                <Switch
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    defaultChecked
                                                />
                                            </Form.Item>
                                        : item.type.type == "ObjectId" ? 
                                            <Form.Item
                                                label={item.title} name={item.title} rules={[
                                                    {
                                                        required: false,
                                                        message: 'Please insert a ' + item.title,
                                                    },
                                                    ]} tooltip={item.description} onChange={this.onChange} initialValue={tableRes[item.title]}
                                            >
                                                {objectids[item.title].length > 0 && (
                                                    <Select
                                                        /*defaultValue="readwrite"*/
                                                        style={{width: "100%"}}
                                                        onChange={this.onChange}
                                                        name={item.title}
                                                        options={objectids[item.title]}
                                                    />
                                                )}
                                            </Form.Item>
                                        :
                                            <Form.Item
                                                label={item.title} name={item.title} rules={[
                                                    {
                                                        required: false,
                                                        message: 'Please insert a ' + item.title,
                                                    },
                                                    ]} tooltip={item.description} onChange={this.onChange} initialValue={tableRes[item.title]}
                                            >
                                                <Input className='rounded-[4px] focus:border-indigo-600 hover:border-indigo-200'  />
                                            </Form.Item>
                                    }
                                        

                                </div>}
                            />
                        </List.Item>
                        )}
                        />
                </div>
                </Form>
            </div>
        )
    }

}

export default withRouter(ContentMain);
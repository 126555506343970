import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

let auth = JSON.parse(localStorage.getItem('_us'));

const PrivateRoute = () => {

    return auth ? <Outlet /> : <Navigate to="/" />;
}

const setKeyPrivateRoute = (key) => {
    auth = key;
    localStorage.setItem('_us', JSON.stringify(key));
    return key;
}

const getKeyPrivateRoute = () => {
    return auth;
}

const removeKeyPrivateRoute = () => {
    auth = null;
    localStorage.removeItem('_us');
}


export {
    setKeyPrivateRoute,
    getKeyPrivateRoute,
    removeKeyPrivateRoute
};

export default PrivateRoute;
import React from 'react';
import axios from 'axios';
import {getKeyPrivateRoute} from '../../PrivateRoute';
import CardSchema from '../../schema/cardList';
import { withRouter } from '../../withRouter';
import DropdownButton from "../../dropdown-button";
import Table from "../../content/table/table";
import { Form, Input, Button, Space, Tag, message, Tooltip  } from 'antd';
import { PlusOutlined, SearchOutlined, EditOutlined,DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Link as LinkTo } from 'react-router-dom';
import { Link as LinkIcon } from '@phosphor-icons/react';
import {  Check, X, Copy, Trash ,DotsThree,Play,ArrowUpRight } from "@phosphor-icons/react";


class ContentMain extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            token: getKeyPrivateRoute().token,
            table: [],
            columns: [],
            dataSource: [],
            filterTable: null,
            hasSelected: [],
            modelId: '',
            loadingTable: true,
            dataDecf: []
        }

    }

    componentDidMount(){
        const { load, params} = this.props;
        
        this.setState({
            modelId: params.table,
        });
        this.load();

        localStorage.removeItem('cp');

        
    
    }

    componentDidUpdate = () => {

        /*if(this.props.load){
            this.props.forceloadStop();
            this.state.modelId = null;
            this.forceUpdate();
        }*/

        this.load();

        if(window.location.hash != null){ 
            const id = window.location.hash.replace("#", "");
            if(document.querySelector("tr[data-row-key='"+id+"']")){ console.log("res", id);
                document.querySelector("tr[data-row-key='"+id+"']").style.background = "rgb(239 255 0 / 16%)";
            }
        }
    }

    load = async() => {
        const {params } = this.props;
        const {modelId} = this.state;
        
    
        const headers = {
            "auth-token": this.state.token
        }
        let columns_ = [];

        if(modelId === params.table) return;


        this.setState({
            modelId: params.table,
            loadingTable: true,
            dataDecf: []
        });


        await axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collection/"+params.table, { headers }).then((response) => {
            this.setState({table: response.data});
            const schema = response.data.schema;
            
            const trg = []
            Object.keys(schema).map((dt, i) => { 
                const type = Object.values(schema)[i].type;
                const target = Object.values(schema)[i].__target;
                const TID = Object.values(schema)[i].__;
                if(target != undefined){
                    trg[dt] = {value: target, ID: TID}
                    this.setState({
                        dataDecf: trg
                    });
                    
                }

                columns_[i] = {
                    title: dt,
                    dataIndex: dt,
                    width: 120,
                    //align: 'center',
                    render: (text, _) => {

                        return (dt === "ID" ? 
                        <div className="flex">
                            <Tag color={'default'}  className='relative bg-gray-200 font-medium whitespace-nowrap overflow-hidden text-ellipsis '>{
                                text
                            }</Tag>
                        </div> 
                        :
                        type === "Boolean" ? 
                            <Tooltip placement="left" title={text === true ? "true" : "false"}><span className='w-6 h-6 bg-gray-100/60 rounded text-center pt-1 justify-center content-center flex'>{text === true ? <Check size={15} /> : <X size={15} />}</span></Tooltip>
                        : 
                        type === "String" ? 
                            text != undefined ? 
                                    text.split(".").pop() === "png" || text.split(".").pop() === "jpg" || text.split(".").pop() === "jpeg" || text.split(".").pop() === "gif" ? 
                                    <img src={text} alt="Image string" width="36px" />
                                :
                                <div className="flex"> <span className='relative whitespace-nowrap overflow-hidden'>{text == "null" ? "-" : text}</span></div>
                            : text
                        : type === "Date" ? 
                            text === null ? 
                                "-"
                            :
                                new Date(text).toLocaleDateString("it-IT", {
                                    dateStyle: "medium",
                                })
                        : type === "ObjectId" ? 
                            <div className="flex">
                                {text === undefined || text === null  ? "-" : 
                                    <LinkTo to={"/project/"+params.id+"/content/" + trg[dt].ID + "#" + (typeof text == "object" ? text.ID : JSON.parse(text).ID)} className='relative whitespace-nowrap overflow-hidden text-indigo-600 p-2 rounded hover:bg-indigo-600/10'>
                                        <Tooltip placement="left" title={typeof text == "object" ? text.name : JSON.parse(text).name}>
                                            <LinkIcon size={20} className=''/>
                                        </Tooltip>
                                    </LinkTo>
                                }
                            </div>
                        : <div className="flex"><span className='relative whitespace-nowrap overflow-hidden'>{text}</span></div>) 
                    }
                }
            })
            
            this.setState({columns: columns_, loadingTable: false});
            
            this.setState( prevState => ({
                columns: [{
                    title: '',
                    key: 'operation',
                    fixed: 'left',
                    align: 'center',
                    width: 50,
                    render: (_, record) => <LinkTo to={"/project/"+params.id+"/content/"+params.table + "/edit/"+ record.ID} className='text-indigo-500 hover:text-indigo-700'><Button type="text" icon={<EditOutlined className='relative top-[-3px]' />} /></LinkTo>,
                  }, ...prevState.columns]
            }));

            this.loadTable();

        }).catch((err) => { 
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: "access error!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }


    hasSelected = (e) => { console.log(e);
        this.setState({hasSelected: e});
    }

    loadTable = () => {
        const {params } = this.props;
        
        const headers = {
            "auth-token": this.state.token
        }
        const dcs = []

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/content/"+params.table, { headers }).then((response) => {
            
            response.data.forEach((elm, i) => { 
                
                const {dataDecf} = this.state;
                const nameDataDecf = Object.keys(dataDecf);
                
                function contenutoObject(objeto) {
                    const entrada = Object.entries(objeto).find(([clave, valor]) => typeof valor === 'object' && valor !== null);
                    return entrada ? entrada[0] : null;
                }

                dcs[i] = elm;
                dcs[i].key = i;
                if(elm._id){
                    dcs[i].ID = elm._id
                }
              
                if(nameDataDecf.length > 0){

                    nameDataDecf.forEach((element, a) => {
                        const valDataDecf = Object.values(dataDecf)[a].value;

                        if(element != undefined){
                            
                            if(elm[element] != undefined){
                                dcs[i][element] = {
                                    name: elm[element][valDataDecf],
                                    ID: elm[element]._id
                                };
                                
                                //dcs[i][element] = elm[element][valDataDecf];
                            }
                        }
                    });
                }

                if(contenutoObject(dcs[i])){
                    
                    dcs[i][contenutoObject(dcs[i])] = JSON.stringify(dcs[i][contenutoObject(dcs[i])]).toString()
                }

                delete dcs[i]._id;
                delete dcs[i].__v;
            });

            /*this.setState( prevState => ({
                dataSource: [...prevState.dataSource,  dcs]
            }));*/
           
            this.setState({dataSource: dcs});
            
        }).catch((err) => {   console.log(err);
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: "access error!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }

    copyAction = (e) => {
        const {hasSelected, dataSource}= this.state;
        const {params} = this.props;
        localStorage.setItem('cp', JSON.stringify(dataSource[hasSelected]));
        this.props.navigate("/project/"+params.id+"/content/"+params.table+"/new")
    }

    removeAction = (e) =>{
        const {hasSelected, dataSource}= this.state;
        const {params } = this.props;
        const headers = {
            "auth-token": this.state.token
        }

        hasSelected.forEach(key => {
            const _id = key;
            axios.delete(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/content/"+params.table+ "/" + _id, { headers }).then((response) => {
                const newData = dataSource.filter(item => !hasSelected.includes(item.ID));
                this.setState({
                    dataSource: newData,
                    hasSelected: []
                });
            }).catch((err) => {   console.log(err);
                if(err.code === "ERR_NETWORK"){
                    message.open({
                        type: 'error',
                        content: "Nessuna connessione con il server!",
                    });
                }else if(err.code === "ERR_BAD_REQUEST"){
                    message.open({
                        type: 'error',
                        content: "access error!",
                    });
                }else{
    
                    message.open({
                        type: 'error',
                        content: err.response,
                    });
                }
                
            });

        });
        
    }

    render(){
        const {params } = this.props;
        const {filterTable, dataSource, hasSelected,columns, table,loadingTable} = this.state;

        
        return (
            <div className="first">
                <div className="p-3 py-2 px-4 flex justify-between  items-center border-b border-grey-500">
                    <div className="flex justify-start items-center">
                        <h1 className='text-xl font-medium my-2 mr-2'>{table.name}</h1>
                        <DropdownButton 
                            buttonClassName="inline-flex w-full justify-center rounded-md ml-2 px-1 py-1 text-default font-medium text-gray-500 hover:!bg-gray-100 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" 
                            dropdownClassName="z-50 absolute right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            childrenButton={<DotsThree size={30} weight="bold"/>} 
                            dropList={[
                                {
                                    label: <LinkTo to={"/project/"+params.id+"/master/playground"}>Play API</LinkTo>,
                                    key: '0',
                                    icon: <Play size={11} weight="bold" />
                                },
                                {
                                    label: <LinkTo to={"/project/"+params.id+"/schema/model/"+ params.table}>Visualizza Model</LinkTo>,
                                    key: '1',
                                    icon: <ArrowUpRight size={11} weight="bold" />
                                }
                            ]}
                            chevron={false} 
                        />
                    </div>
                    <div className="flex justify-start items-center"> 
                        <LinkTo to={"/project/"+params.id+"/content/"+params.table+"/new"}><Button className='bg-indigo-500 hover:!bg-indigo-600' type="primary" icon={<PlusOutlined className='relative block top-[-3px]' />}>Aggiungi voce</Button></LinkTo>
                    </div>
                </div>

                <div>
                    <div className="border-b border-grey-500">
                        <Form
                            name="normal_login"
                            className="login-form flex items-center"
                            initialValues={{ remember: true }}
                        >
                            
                            <Form.Item
                                name="name"
                                className="mx-3 my-2 "
                            >
                                <Input 
                                    name="name" 
                                    prefix={<SearchOutlined  className="site-form-item-icon" />}  
                                    placeholder="Nome progetto" 
                                    onChange={e => {

                                        const { dataSource } = this.state;

                                        const filterTable = dataSource.filter(o =>
                                            Object.keys(o).some(k =>
                                                String(o[k])
                                                .toLowerCase()
                                                .includes(e.target.value.toLowerCase())
                                            )
                                        );

                                        this.setState({ filterTable }); 
                                    }}
                                />
                            </Form.Item>
                            <Form.Item className="mx-3 my-2 h-full">
                                <Button type="primary" className="bg-indigo-500 hover:bg-indigo-600 h-full block">Remove filter</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="border-b border-grey-500 flex">
                        <span className="mx-3 my-2 text-xs block relative text-gray-700">
                            {hasSelected.length} entries selected 
                        </span>
                        {
                            hasSelected.length === 1 ? 
                                <span className='items-center justify-center flex'>
                                    <button onClick={this.copyAction} className='items-center justify-center flex text-green-500 hover:text-green-800 hover:bg-green-800/10 rounded px-1 h-[20px] py-1'><Copy size={16} /> <span className='text-sm text-green-800 ml-1'>Copia</span></button>
                                </span>
                            :
                            
                            ""
                        }
                        {
                            hasSelected.length > 0 ? 
                                <span className='items-center justify-center flex'>
                                    <button onClick={this.removeAction} className='items-center justify-center flex text-red-500 hover:text-red-800 hover:bg-red-800/10 rounded px-1 h-[20px] py-1'><Trash size={16} /> <span className='text-sm text-red-800 ml-1'>Elimina</span></button>
                                </span>
                            :""
                        }
                    </div>
                    <Table 
                        loading={loadingTable} 
                        actionFColumn={this.props.actionFColumn} 
                        
                        data={filterTable == null ? dataSource : filterTable} 
                        hasSelected={this.hasSelected} 
                        columns={columns} 
                    />
                </div>
            </div>
        )
    }

}

export default withRouter(ContentMain);
import React from "react";
import { FirstColumn, Main } from '../../../components/colums';
import { Button, message, Upload,List, Modal,Form, Input, Select } from 'antd';
import { PlusOutlined,DeleteOutlined,ExclamationCircleFilled } from '@ant-design/icons';
import { withRouter } from '../../../components/withRouter';
import {getKeyPrivateRoute} from '../../../components/PrivateRoute';
import axios from 'axios';



class Index extends React.Component{

    constructor(props){
        super(props);

        
        const this_ = this;
        const {params} = this.props;

        this.state = {
            token: getKeyPrivateRoute().token,
            removeLoading: false,
            isModalOpen: false,
            form: {
                name: "",
                expiration: ""
            },
            sxCls: {
                title: "Settings",
                collapses: [
                    {
                        title: "General",
                        id: null,
                        menu: [
                            {
                                title: "Project",
                                link: "/project/"+params.id+"/settings/project"
                            },
                            {
                                title: "BackUp",
                                link: "/project/"+params.id+"/settings/backup",
                            },
                            {
                                title: "Tokens Public Api",
                                link: "/project/"+params.id+"/settings/project/api-tokens",
                                active: true
                            }
                        ]
                    },
                    {
                        title: "Access",
                        id: null,
                        menu: [
                            {
                                title: "Roles & Permissions",
                                link: "/project/"+params.id+"/settings/permissions"
                            },{
                                title: "Team members",
                                link: "/project/"+params.id+"/settings/teams"
                            }
                        ]
                    }
                ]
            },
            tokens: [],
            newToken: ""
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
        const {params} = this.props;
        const headers = {
            "auth-token": this.state.token
        }
        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/tokens", { headers }).then((response) => {
            //console.log(response.data);
            response.data.forEach(token => {
                this.setState(prevState => ({
                    tokens: [...prevState.tokens, {name: token.name, token: token.token}]
                }));
            });
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }

    onFinish = (value) => {
        console.log(value);
    };

    enterLoading = (index) => {
        this.setState({
            removeLoading: true
        });
        
        
        setTimeout(() => {
            this.setState({
                removeLoading: false,
            });

            
            this.setState(prevState => ({
                tokens: [...prevState.tokens, {name: "tokwen #3", token: "UIHEYHUCIRYW47CRY37.sdkjfncdjfb"}]
            }));
            

            Modal.success({
                title: "Token creato con succeso",
                content: <>
                    <b>Token:</b>
                    <p><u>UIHEYHUCIRYW47CRY37.sdkjfncdjfb</u></p>
                    <b>In data:</b>
                    <p>26 Maggio 2023</p>
                    <b>Validità fino a</b>
                    <p>26 Maggio 2024 </p>
                </>,
                okButtonProps: {
                    className: " bg-green-600 hover:!bg-green-700"
                },
            });
        }, 1000);
    };

    removeToken = (e) => {
        const tokens = this.state.tokens;
        const { confirm } = Modal;
        const this_ = this;
        const {params} = this.props;
        const headers = {
            "auth-token": this.state.token
        }

        confirm({
            title: 'Elimina token?',
            icon: <ExclamationCircleFilled />,
            okButtonProps: {
                className: " bg-green-600 hover:!bg-green-700"
            },
            content: <>
                    <p>Sei sicuro di voler eliminare questo token? </p>
                    <p>Non potrai più riutilizzarlo, assicurati che non sia più utilizzato su alcun progetto.</p>
                </>,
            onOk() {

                return axios.delete(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/remove/token", {
                    headers,
                    data: {
                      token: e.token
                    }
                  }).then((response) => {
                    const index = tokens.findIndex(tokenObj => tokenObj.token === e.token);
                    if (index !== -1) {
                        tokens.splice(index, 1);
                    }
                    

                    message.open({
                        type: 'success',
                        content: 'Token eliminato',
                    });

                    this_.setState(tokens);
                }).catch((err) => { console.log(err)
                    if(err.code === "ERR_NETWORK"){
                        message.open({
                            type: 'error',
                            content: "Nessuna connessione con il server!",
                        });
                    }else{

                        message.open({
                            type: 'error',
                            content: err.response,
                        });
                    }
                    
                });
                
              
            },
            onCancel() {},
          });

        
    }

    showModal = () => {
        this.setState({
            isModalOpen: true
        });
    };
    
    handleOk = () => {
        const {form} = this.state;
        const {params} = this.props;
        const headers = {
            "auth-token": this.state.token
        }
        const this_ = this;


        const today = new Date();              //  24 ore * 60 minuti * 60 secondi
        const nextWeek = new Date(today.getTime() + form.expiration * 60 * 60 * 1000);


        axios.post(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/add/token", {
            
            "name": form.name,
            "expired": nextWeek
        
        }, { headers }).then((response) => {
            console.log(response.data);

            var options = { year: 'numeric', month: 'long', day: 'numeric' };
            const td = new Date().toLocaleDateString("it-IT", options);
            
            const confermedate = new Date(today.getTime() + form.expiration * 60 * 60 * 1000).toLocaleDateString("it-IT", options);
            

            Modal.success({
                title: "Token creato con succeso",
                content: <>
                    <b>Token:</b>
                    <p><u>{response.data.token}</u></p>
                    <b>In data:</b>
                    <p>{td}</p>
                    <b>Validità fino a</b>
                    <p>{confermedate} </p>
                </>,
                okButtonProps: {
                    className: " bg-green-600 hover:!bg-green-700"
                },
            });

            this_.setState(prevState => ({
                tokens: [...prevState.tokens, {name: response.data.name, token: response.data.token}]
            }));
            this_.setState({
                isModalOpen: false
            });
            //this.forceUpdate()
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: err.response.data,
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });

        
    };
    
    handleCancel = () => {
        this.setState({
            isModalOpen: false
        });
    };

    onRequiredTypeChange = (e) => {
        console.log(e);
    }

    handleChange = (e) => {
        if(typeof e === "object"){
            const value = e.target.value;
            const {form} = this.state;
            form.name = value;
            this.setState(form);
        }else{
            const {form} = this.state;
            form.expiration = e;
            this.setState(form);
        }

    }

    render(){
        const { sxCls, removeLoading, tokens,isModalOpen, form } = this.state;


        const today = new Date();              //  24 ore * 60 minuti * 60 secondi
        let nextWeek = "";
        if(form.expiration != ""){
            var options = { year: 'numeric', month: 'long', day: 'numeric' };
            nextWeek = new Date(today.getTime() + form.expiration * 60 * 60 * 1000).toLocaleDateString("it-IT", options);
            
        }
        
        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500 ">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1 overflow-y-auto scrollbar">
                    {/*<!-- Right column content -->*/}
                    <div className="first">
                       
                        <div className="px-10 py-10">
                            <h1 className='text-2xl font-bold my-2 mr-2'>Project Public Tokens</h1>
                            <p className="text-gray-500">Crea e gestisci le tue chiavi d'accesso per poter utilizzare le tue api in modo pubblico.</p>


                            <div className="mt-10 bg-white shadow-md mb-2 rounded item py-6 px-8 border border-grey-400 max-w-2xl">
                                <h2 className='text-xl font-bold my-2 mr-2'>Tokens List</h2>

                                <List
                                    dataSource={tokens}
                                    renderItem={(item) => (
                                            <List.Item >
                                                <span className="relative block w-full">{item.name}</span>
                                                <Button type="link" onClick={() => this.removeToken(item)} className="text-red-400 hover:!text-red-600" icon={<DeleteOutlined className="relative top-[-4px]" />} size="small"></Button>
                                            </List.Item>
                                        )}
                                    />
                            </div>

                            <div className="mt-10">
                                <h2 className='text-xl font-bold my-2 mr-2'>Aggiungi chiave Privata</h2>
                                <p className="mt-2 text-sm text-gray-500">
                                    Deletes the project permanently. This cannot be undone.
                                </p>

                                <Button
                                    type="primary"
                                    icon={<PlusOutlined  className="relative top-[-4px]" />}
                                    
                                    loading={removeLoading}
                                    
                                    className="mt-6 bg-green-600 hover:!bg-green-700"
                                    //onClick={() => this.enterLoading(1)}
                                    onClick={this.showModal}
                                    >
                                    Add Token
                                </Button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <Modal title="Crea token" open={isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel} okButtonProps= {{
                        className: " bg-green-600 hover:!bg-green-700",
                        disabled: this.state.form.name.length === 0 || this.state.form.expiration.length === 0
                    }}>
                    
                    <Form
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        layout="vertical"
                        onValuesChange={this.onRequiredTypeChange}
                    >
                        <Form.Item label="Name" required tooltip="Inserisci un nome per definire il token">
                            <Input placeholder="Token 1" onChange={this.handleChange} name="name" />
                        </Form.Item>
                        <Form.Item
                            label="Scadenza token"
                            required
                            tooltip={{
                                title: 'Imposta la scadenza del tuo token, ricorda non potrai più modificare questo campo.'
                            }}
                        >
                            <Select
                                defaultValue="no_expiration"
                                style={{
                                    width: '100%',
                                }}
                                onChange={this.handleChange} name="expiration"
                                options={[
                                    {
                                        value: '24',
                                        label: '24 Ore',
                                    },
                                    {
                                        value: '48',
                                        label: '48 Ore',
                                    },
                                    {
                                        value: '168',
                                        label: '1 settimana',
                                    },
                                    {
                                        value: '730',
                                        label: '1 Mese',
                                    },
                                    {
                                        value: '8760',
                                        label: '1 Anno',
                                    },
                                    {
                                        value: '87600',
                                        label: 'Senza scadenza',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Form>
                    {
                        form.expiration != "" ? <>
                            <p>Data di scadenza token: </p>
                            <p>{nextWeek}</p>
                        </> : ""
                    }
                </Modal>
            </>
        );
    }
}

export default withRouter(Index);
import React, {useState} from "react";
import { Button, Popconfirm } from 'antd';
import { LinkSimple, NumberCircleEight, Pi, ToggleLeft, Paperclip, MapPin, BracketsCurly, Link, Calendar } from "@phosphor-icons/react";

const def_element = ['id', 'createat', 'updateat']

export default function Item (props){
    const {item } = props;

    const opacity =  1;

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showPopconfirm = () => {
        setOpen(true);
    };

    const handleOk = (e) => {
        setConfirmLoading(true);

       

        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
            props.action(props.item);
        }, 2000);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    return (
        <div className={[" bg-white shadow-md mb-2 rounded item w-full flex py-4 px-3 border border-grey-400 group/item bg-white", def_element.includes(item.title.toLowerCase()) ? "!bg-gray-50": ""].join(" ")}>
            
            <div className="select-none center flex justify-center items-center mr-2 opacity-0">
                <span className="material-symbols-outlined">drag_indicator</span>
            </div>
            
            <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                <div className="color-badge"></div>
                {
                    item.type === "single-line-text" ? 
                        <div className="mr-4 bg-emerald-50 text-emerald-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                    : item.type === "Date" ? 
                        <div className="mr-4 bg-blue-50 text-blue-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <Calendar size={25} />
                        </div>
                    : item.type === "ObjectId" ? 
                        <div className="mr-4 bg-purple-50 text-purple-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <Link size={25} />
                        </div>
                    : item.type === "numbers" ? 
                        <div className="mr-4 bg-orange-50 text-orange-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <NumberCircleEight size={25} />
                        </div>
                    : item.type === "decimal128" ? 
                        <div className="mr-4 bg-amber-50 text-amber-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <Pi size={25} />
                        </div>
                    : item.type.toLowerCase() === "boolean" ? 
                        <div className="mr-4 bg-rose-50 text-rose-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <ToggleLeft size={25} />
                        </div>
                    : item.type === "assets_pic" ? 
                        <div className="mr-4 bg-fuchsia-50 text-fuchsia-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <Paperclip size={25} />
                        </div>
                    :item.type === "geo" ? 
                        <div className="mr-4 bg-indigo-50 text-indigo-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <MapPin size={25} />
                        </div>
                    : item.type === "array" ? 
                        <div className="mr-4 bg-pink-50 text-pink-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <BracketsCurly size={25} />
                        </div>
                    : 
                     <div className="mr-4 bg-emerald-50 text-emerald-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                }
                <div className="mr-2 grid grid-rows-2">
                    <span className="font-medium">
                        {item.title}
                    </span>
                    <div className="flex">
                    {
                        item.tags.map((tag, i) => {
                            if(tag == "undefined")return 
                            return <span key={i} className="mr-1 font-medium bg-slate-100 rounded text-slate-500 text-xs relative center flex justify-center items-center px-2">{tag}</span>
                        })
                    }
                    {
                        def_element.includes(item.title.toLowerCase()) ? 
                            <span className="mr-1 font-medium bg-slate-100 rounded text-slate-500 text-xs relative center flex justify-center items-center px-2">System Field</span>
                        : ""
                    }
                    {
                        def_element.includes(item.title.toLowerCase()) ? 
                            "" 
                        : <Popconfirm
                                title="Rimuovi field"
                                description="Sei sicuro di voler eliminare questo field?"
                                open={open}
                                onConfirm={handleOk}
                                okButtonProps={{ loading: confirmLoading }}
                                onCancel={handleCancel}
                            >
                                <Button onClick={showPopconfirm} type="primary" className="hover:bg-red-900 hidden group-hover/item:flex" danger size={'small'}>Delete field</Button>
                            </Popconfirm>
                            
                    }
                    </div>
                </div>
            </div>
        </div>
    );

}
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import React from 'react';
import './dropdown-style.css';
import {
    EditOutlined,
    DeleteOutlined,
    CopyOutlined
  } from '@ant-design/icons';


export default function DropdownMenu (props) {

    /*const items = [
        {
            label: <a href="https://www.antgroup.com">Edit</a>,
            key: '0',
            icon: <EditOutlined />
        },
        {
            label: <a href="https://www.aliyun.com">Duplicate</a>,
            key: '1',
            icon: <CopyOutlined />
        },
        {
            type: 'divider',
        },
        {
            label: 'Remove',
            icon: <DeleteOutlined />,
            danger: true,
            key: '3',
            onClick: props.remove
        }
    ];*/

    const items = props.dropList;
    
    return (
        <Dropdown
            menu={{items}}
            trigger={['click']}
        >
            <a onClick={(e) => e.preventDefault()} className="flex center justify-center hover:text-gray-600 text-gray-500 rounded hover:bg-gray-100 px-1 cursor-pointer ml-3">
                {props.childrenButton}
            </a>
        </Dropdown>
    );
};
import React from "react";
import { withRouter } from '../../components/withRouter';


class Index extends React.Component{

    constructor(props){
        super(props);


        this.state = {

        }
    }

    componentDidMount(){
       /* const headers = {
            "auth-token": this.state.token
        }
        
        //Loading all projects
        axios.get(process.env.REACT_APP_END_POINT + "/projects", { headers }).then((response) => {
            
            this.setState({
                projects: response.data 
            });
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response.data,
                });
            }
            
        });*/
       console.log(this.props);
    }

    render (){

        const { useOutletContext } = this.props;

        return (
            <div className="flex w-full ">
                <div className="center justify-center w-[700px] mx-auto mt-20 text-center">

                    <h1 className="text-6xl font-bold">Benvenuto {useOutletContext.user.name}</h1>
                    <br />
                    <h1 className="text-3xl font-semibold ">{useOutletContext.project.name}</h1>
                    <br />
                    <p className="">
                        Modifica il tuo progetto con Admin Panel by Espoweb
                    </p>

                </div>
            </div>
        );
    }
}

export default withRouter(Index);
import React from 'react';
import {Link} from "react-router-dom";
import { createFromIconfontCN } from '@ant-design/icons';

class SchemaMain extends React.Component {

    constructor(props){
        super(props);
    }

    render(){
        const IconFont = createFromIconfontCN({
            scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
          });
        return (
            <div className="first">

                <div className="flex ">
                    <div className="w-full max-w-[996px] py-14 px-14  overflow-auto scrollbar h-[calc(100vh_-_65px)] ">
                        <h1 className="text-2xl font-medium my-2">Models</h1>
                        <p className="mt-6 text-slate-500 text-[.9rem]">Your schema is the content structure of your project. You can define your schema by creating models and adding fields, reusable components, and sidebar widgets to them, as well as integrating remote sources and establishing relationships with other models.</p>
                        <Link to="" className="block my-6 text-[.8rem] text-indigo-500">Learn more <IconFont type="icon-tuichu" className="relative top-[-3px]"/></Link>
                    </div>
                </div>
            </div>
        )
    }

}

export default SchemaMain;
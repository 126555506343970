import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {Tabs, Select, Option ,Button, Radio, Form, Input, message} from 'antd'
import { useParams } from 'react-router-dom';
import {getKeyPrivateRoute} from '../../../components/PrivateRoute';
import axios from 'axios';
import { LinkSimple, NumberCircleEight, Pi, ToggleLeft, Paperclip, MapPin, BracketsCurly, Link, Calendar } from "@phosphor-icons/react";

export default function Example(props) {
  //const [open, setOpen] = useState(props.show)
    const cmp = props.sidebarComponents;
    let sp = cmp === "single-line-text" ? "String" : cmp === "slug" ? "String" : cmp;

    let { id, model } = useParams();
    const [title, setTitle] = useState('');
    const [val, setVal] = useState(sp);
    const [permission, setPermission] = useState('');
    const [permissionKey, setPermissionKey] = useState('');
    const [msListKey, setMsListKey] = useState([]);
    
    const [loginAction, setLoginAction] = useState(false);
    const [modelsList, setModelsList] = useState([]);
    
    
    const onLoad = () => {
        
        const headers = {
            "auth-token": getKeyPrivateRoute().token
        }

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ id +"/collections", { headers }).then((response) => {
            //
            let mslist = [];
            let i = 0;
            response.data.forEach(element => {
                if(element.name !== undefined){
                    mslist[i] = {
                        value: element._id,
                        label: element.name
                    }
                    setModelsList(mslist);
                    i++;
                }
            });

        }).catch((err) => { 
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });

        
    };


    const onFinish = (values) => {
        //console.log('Success:', values);
        const {name} = values;
        
        const headers = {
            "auth-token": getKeyPrivateRoute().token
        }
        let cls = {}
        
        if(sp == "ObjectId"){
            const permissionID = permission.split("|")[1];
            const permissionName = permission.split("|")[0];
            cls = {
                [name]: {
                    type: 'ObjectId',
                    ref: permissionName,
                    __: permissionID,
                    __target: permissionKey
                }
            }
        }else{
            cls = {
                [name]: {
                    type: sp
                }
            }
        }

        
        axios.post(process.env.REACT_APP_END_POINT + "/project/"+id+"/collection/"+ model, cls, { headers }).then((response) => {
            message.open({
                type: 'success',
                content: "Field added successfully!",
            });
            
            props.setOpen();
            props.success(cls);
            /*setTimeout(() => {
                props.navigate("/project/"+ id + "/schema");
                this.forceUpdate()
            }, 1000);*/
            
        }).catch((err) => {
            
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else
            if(err.response.status === 400){
                message.open({
                    type: 'error',
                    content: err.response.data.message,
                });
            }else if(err.response.status === 401){
                message.open({
                    type: 'error',
                    content: "Connection refused",
                });
            }

            setTimeout(() => {
                setLoginAction(false);
            }, 600);
        });

        
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = val => {
        setTitle(val);
    }
    const onChangePermission = val => {
        setPermission(val);
        const headers = {
            "auth-token": getKeyPrivateRoute().token
        }

        const mslistk =[];
        let i = 0;
        axios.get(process.env.REACT_APP_END_POINT + "/project/"+id+"/collection/"+ val.split('|')[1], { headers }).then((response) => {
            
            Object.keys(response.data.schema).map((name) => {
                if(response.data.schema[name].type != "ObjectId"){
                    mslistk[i] = {
                        value: name,
                        label: name
                    }
                    i++;
                }
                
            });
            setMsListKey(mslistk);
        }).catch((err) => {
            console.log("Error: ", err);
            message.open({
                type: 'error',
                content: "Errore in log",
            });
        });

    }
    const onChangePermissionKey = val => {
        setPermissionKey(val);
    }

    const onChangeVal = val => {
        setVal(val);
    }


    //onLoad();
    let mslist = [];
    if(props.collections){
        let i = 0;
        Object.values(props.collections).map(element => {
            if(element.name !== undefined){
                if(props.params.model != element._id){
                    
                    mslist[i] = {
                        value: element.name+"|"+element._id,
                        label: element.name
                    }
                    //setModelsList(mslist);
                    i++;

                }
            }
        })
    }
    
    return (
        <Transition.Root show={props.show} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                        

                        {/* Modal Window */}
                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                            <div className="px-4 sm:px-6 bg-gray-100 py-6 flex">
                            <div className={["mr-4 shadow w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center", 
                                cmp === "slug" ? 
                                    "bg-emerald-50 text-emerald-800"
                                : cmp === "numbers" ? 
                                    "bg-orange-50 text-orange-800"
                                :cmp === "decimal128" ? 
                                    "bg-amber-50 text-amber-800" 
                                :cmp === "boolean" ? 
                                    "bg-rose-50 text-rose-800"
                                :cmp === "date" ? 
                                    "bg-blue-50 text-blue-800"
                                :cmp === "assets_pic" ? 
                                    "bg-fuchsia-50 text-fuchsia-800"
                                :cmp === "geo" ? 
                                    "bg-indigo-50 text-indigo-800"
                                :cmp === "array" ? 
                                    "bg-pink-50 text-pink-800"
                                :cmp === "ObjectId" ? 
                                    "bg-purple-50 text-purple-800"
                                :
                                    "bg-emerald-50 text-emerald-800"  
                            ].join(" ")}>
                                {
                                    cmp === "slug" ? 
                                        <LinkSimple size={25} />
                                    : cmp === "numbers" ? 
                                        <NumberCircleEight size={25} />
                                    :cmp === "decimal128" ? 
                                        <Pi size={25} />
                                    :cmp === "boolean" ? 
                                        <ToggleLeft size={25} />
                                    :cmp === "date" ? 
                                        <Calendar size={25} />
                                    :cmp === "assets_pic" ? 
                                        <Paperclip size={25} />
                                    :cmp === "geo" ? 
                                        <MapPin size={25} />
                                    :cmp === "array" ? 
                                        <BracketsCurly size={25} />
                                    :cmp === "ObjectId" ? 
                                        <Link size={25} />
                                    :
                                        "T"  
                                }
                            </div>
                            <div className="w-full">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                    {
                                        cmp === "slug" ? 
                                            "Add Slug"
                                        : cmp === "numbers" ? 
                                            "Add  Number"
                                        :cmp === "decimal128" ? 
                                            "Add Decimal"
                                        :cmp === "boolean" ? 
                                            "Add Boolean"
                                        :cmp === "date" ? 
                                            "Add Date"
                                        :cmp === "assets_pic" ? 
                                            "Add Asset"
                                        :cmp === "geo" ? 
                                            "Add Map"
                                        :cmp === "array" ? 
                                            "Add Json"
                                        :cmp === "ObjectId" ? 
                                            "Add Reference"
                                        :
                                            "Add headings and title"  
                                    }
                                </Dialog.Title>
                                <p className='text-sm text-gray-600 mt-1'>
                                    {
                                        cmp === "slug" ? 
                                            "Url identifier"
                                        : cmp === "numbers" ? 
                                            "ID, quantity, etc."
                                        :cmp === "decimal128" ? 
                                            "Rating, price, etc."
                                        :cmp === "boolean" ? 
                                            "True or false"
                                        :cmp === "date" ? 
                                            "Calendar date picker w/time"
                                        :cmp === "assets_pic" ? 
                                            "Support any file type"
                                        :cmp === "geo" ? 
                                            "Geo coordinates"
                                        :cmp === "array" ? 
                                            "Arbitary JSON structures"
                                        :cmp === "ObjectId" ? 
                                            "Content relationship table"
                                        :
                                            "Single line"  
                                    }
                                </p>
                            </div>
                            <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-500"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-500"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="flex center">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-400 hover:text-gray-500  focus:outline-none"
                                            onClick={props.setOpen}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                            </div>
                            <div className="relative mt-0 flex-1 px-0 sm:px-0">
                                
                                <div className="">
                                    <div className="">
                                        

                                        <Form
                                            onFinish={onFinish}
                                            layout="vertical"
                                            size="large"
                                            requiredMark={'optional'}
                                            autoComplete="off"
                                            className='mt-10 mx-6'
                                        >
                                            <div className="sm:overflow-hidden sm:rounded-md">
                                                <Tabs
                                                    defaultActiveKey="1"
                                                    centered
                                                    className='mt-0'
                                                    items={[
                                                    {
                                                        label: 'Settings',
                                                        key: '1',
                                                        children: (
                                                            <>
                                                                <Form.Item
                                                                    label="Display name" name="name" rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please insert a name!',
                                                                        },
                                                                        ]} tooltip="Nome che verrà visualizzato in APS" onChange={onChange}
                                                                >
                                                                    <Input className='rounded-[4px] focus:border-indigo-600 hover:border-indigo-200' />
                                                                </Form.Item>

                                                                {
                                                                    cmp === "ObjectId" ? 
                                                                        <>
                                                                            <Form.Item
                                                                                label="Scegli il tuo modello di referenza"  name="reference"  required
                                                                            >
                                                                                <Select
                                                                                    /*defaultValue="readwrite"*/
                                                                                    style={{width: "100%"}}
                                                                                    onChange={onChangePermission}
                                                                                    name="permission"
                                                                                    options={mslist}
                                                                                />
                                                                                
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                label="Scegli la chiave di referenza"  name="referenceKey" required
                                                                            >
                                                                                <Select
                                                                                    /*defaultValue="readwrite"*/
                                                                                    style={{width: "100%"}}
                                                                                    disabled={msListKey.length ===0}
                                                                                    onChange={onChangePermissionKey}
                                                                                    name="permissionKey"
                                                                                    options={msListKey}
                                                                                />
                                                                                
                                                                            </Form.Item>
                                                                        </>
                                                                    : ""
                                                                }
                                                            </>
                                                        ),
                                                    },
                                                    {
                                                        label: 'Advanced',
                                                        key: '2',
                                                        children: (
                                                            <Form.Item
                                                                label="Display name" name="title" onChange={onChange}
                                                            >
                                                                    <Select
                                                                        defaultValue="readwrite"
                                                                        style={{width: "100%"}}
                                                                        name="permissions"
                                                                        options={[
                                                                            { value: 'readwrite', label: <div className='block grid grid-column'><span className="font-medium">Read / Write</span><span className='text-gray-600'>Field can be edited e writed</span></div> },
                                                                            { value: 'read', label:  <div className='block grid grid-column'><span className="font-medium">Read only</span><span className='text-gray-600'>Field is shown but can't be endited in UI.</span></div> },
                                                                            { value: 'hidden', label: <div className='block grid grid-column'><span className="font-medium">Hidden</span><span className='text-gray-600'>Field is not shown, but can be used in other field.</span></div> },
                                                                        ]}
                                                                    />
                                                                
                                                            </Form.Item>
                                                        ),
                                                    },
                                                    ]}
                                                />
                                            </div>
                                            
                                            <Form.Item className='mt-10'>
                                                <div className="flex justify-between">
                                                    <Button type="text" onClick={props.setOpen}>
                                                        Cancel
                                                    </Button>
                                                    
                                                    <Button type="primary" htmlType="submit" disabled={title.length <= 0} className="bg-indigo-600 hover:!bg-indigo-800 disabled:hover:bg-gray-800" loading={loginAction}>
                                                        Add model
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>


                            </div>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

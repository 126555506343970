import React from "react";
import { FirstColumn, Main } from '../../components/colums';
import { Button, message, Upload,Avatar } from 'antd';
import { PictureOutlined,DeleteOutlined  } from '@ant-design/icons';
import { withRouter } from '../../components/withRouter';


class Index extends React.Component{

    constructor(props){
        super(props);

        const {params} = this.props;
        const this_ = this;

        this.state = {
            removeLoading: false,
            sxCls: {
                title: "Settings",
                collapses: [
                    {
                        title: "General",
                        id: null,
                        menu: [
                            {
                                title: "Project",
                                link: "/project/"+params.id+"/settings/project",
                                active: true
                            },
                            {
                                title: "BackUp",
                                link: "/project/"+params.id+"/settings/backup",
                            },
                            {
                                title: "Tokens Public Api",
                                link: "/project/"+params.id+"/settings/project/api-tokens",
                                active: false
                            }
                            
                        ]
                    },
                    {
                        title: "Access",
                        id: null,
                        menu: [
                            {
                                title: "Roles & Permissions",
                                link: "/project/"+params.id+"/settings/permissions"
                            },{
                                title: "Team members",
                                link: "/project/"+params.id+"/settings/teams"
                            }
                        ]
                    }
                ]
            }
        }
    }

    onFinish = (value) => {
        console.log(value);
    };

    enterLoading = (index) => {
        this.setState({
            removeLoading: true
        });
        
    
        setTimeout(() => {
            this.setState({
                removeLoading: false
            });
        }, 6000);
    };

    render(){
        const { sxCls, removeLoading } = this.state;
        const {id} = this.props.params;
        const propsb = {
            beforeUpload: (file) => {
              const isPNG = file.type === 'image/png';
              if (!isPNG) {
                message.error(`${file.name} is not a png file`);
              }
              return isPNG || Upload.LIST_IGNORE;
            },
            onChange: (info) => {
              console.log(info.fileList);
            },
          };

        
        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500 ">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1 overflow-y-auto scrollbar">
                    {/*<!-- Right column content -->*/}
                    <div className="first">
                       
                        <div className="px-10 py-10">
                            <h1 className='text-2xl font-bold my-2 mr-2'>Project Information</h1>
                            <p className="text-gray-500">Global settings of your project with options to edit details, clone and delete project.</p>


                            <div className="mt-10 bg-white shadow-md mb-2 rounded item py-6 px-8 border border-grey-400 max-w-2xl">
                                <h2 className='text-xl font-bold my-2 mr-2'>Project details</h2>

                                <form action="#" method="POST">
                                    <div className="sm:overflow-hidden sm:rounded-md">
                                        <div className="space-y-6 bg-white ">
                                            <div className="grid grid-cols-3 grid-flow-col gap-4 ">
                                                <div className="mt-2 col-span-2">
                                                    <div className="">
                                                        <label htmlFor="company-website" className="flex justify-start items-center h-full col-span-1 block text-sm mb-2 leading-6 text-gray-900">
                                                            Display Name
                                                        </label>
                                                        <div className="mt-0 flex rounded-md shadow-sm col-span-5">
                                                            <input
                                                                type="text"
                                                                name="first-name"
                                                                id="first-name"
                                                                autoComplete="given-name"
                                                                className=" block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-4">
                                                        <label htmlFor="company-website" className="flex justify-start items-center h-full col-span-1 block text-sm mb-2 leading-6 text-gray-900">
                                                            Project description (optional)
                                                        </label>
                                                        <div className="mt-0 flex rounded-md shadow-sm col-span-5">
                                                            <textarea
                                                                id="about"
                                                                name="about"
                                                                rows={3}
                                                                className="px-3 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:py-1.5 sm:text-sm sm:leading-6"
                                                                placeholder="you@example.com"
                                                                defaultValue={''}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-4">
                                                        <label htmlFor="company-website" className="flex justify-start items-center h-full col-span-1 block text-sm mb-2 leading-6 text-gray-900">
                                                            Project ID
                                                        </label>
                                                        <div className="mt-0 flex rounded-md shadow-sm col-span-5">
                                                            <input
                                                                type="text"
                                                                name="first-name"
                                                                id="first-name"
                                                                disabled
                                                                autoComplete="given-name"
                                                                value={id}
                                                                className=" block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-4">
                                                        <label htmlFor="company-website" className="flex justify-start items-center h-full col-span-1 block text-sm mb-2 leading-6 text-gray-900">
                                                            Project Region
                                                        </label>
                                                        <div className="mt-0 flex rounded-md shadow-sm col-span-5">
                                                            <input
                                                                type="text"
                                                                name="first-name"
                                                                id="first-name"
                                                                disabled
                                                                value="EUR-CENTRAL-1"
                                                                autoComplete="given-name"
                                                                className=" block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-2 flex center justify-center">
                                                   <div>
                                                        <div>
                                                            <label className="flex justify-start items-center h-full col-span-1 block text-sm mb-2 leading-6 text-gray-900">
                                                                Project Pictutre
                                                            </label>
                                                            <Avatar shape="square" className="bg-gray-900	" size={148} icon={<>PR</>} />
                                                        </div>
                                                        <div className="mt-4">
                                                            <Upload {...propsb}>
                                                                <Button icon={<PictureOutlined className="relative top-[-3px]" />}>Upload png only</Button>
                                                            </Upload>
                                                        </div>
                                                   </div>
                                                </div>
                                            </div>
                                            

                                            </div>
                                            <div className="text-left mt-6">
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-5 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                >
                                                    Save changes
                                                </button>
                                            </div>
                                        </div>
                                </form>
                            </div>

                            <div className="mt-10">
                                <h2 className='text-xl font-bold my-2 mr-2'>Delete project</h2>
                                <p className="mt-2 text-sm text-gray-500">
                                    Deletes the project permanently. This cannot be undone.
                                </p>

                                <Button
                                    type="primary"
                                    icon={<DeleteOutlined  className="relative top-[-4px]" />}
                                    
                                    loading={removeLoading}
                                    danger
                                    className="mt-6"
                                    onClick={() => this.enterLoading(1)}
                                    >
                                    Premove Project
                                </Button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Index);
import React from "react";
import { FirstColumn, Main } from '../../../components/colums';
import { Button, message, Upload,List, Modal,Form, Input, Select } from 'antd';
import { PlusOutlined,DeleteOutlined,DownloadOutlined } from '@ant-design/icons';
import { withRouter } from '../../../components/withRouter';
import {getKeyPrivateRoute} from '../../../components/PrivateRoute';
import axios from 'axios';
import DragAndDrop from '../../../components/uploader/DragAndDrop';


class Index extends React.Component{

    constructor(props){
        super(props);

        
        const this_ = this;
        const {params} = this.props;

        this.state = {
            token: getKeyPrivateRoute().token,
            removeLoading: false,
            isModalOpen: false,
            form: {
                name: "",
                expiration: ""
            },
            sxCls: {
                title: "Settings",
                collapses: [
                    {
                        title: "General",
                        id: null,
                        menu: [
                            {
                                title: "Project",
                                link: "/project/"+params.id+"/settings/project",
                            },
                            {
                                title: "BackUp",
                                link: "/project/"+params.id+"/settings/backup",
                                active: true
                            },
                            {
                                title: "Tokens Public Api",
                                link: "/project/"+params.id+"/settings/project/api-tokens",
                            }
                        ]
                    },
                    {
                        title: "Access",
                        id: null,
                        menu: [
                            {
                                title: "Roles & Permissions",
                                link: "/project/"+params.id+"/settings/permissions"
                            },{
                                title: "Team members",
                                link: "/project/"+params.id+"/settings/teams"
                            }
                        ]
                    }
                ]
            },
            logs: [],
            newToken: ""
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
        const {params} = this.props;
        const headers = {
            "auth-token": this.state.token
        }
        axios.get(process.env.REACT_APP_END_POINT + "../../../project/"+ params.id +"/backupdb", { headers }).then((response) => {
            this.setState({
                logs: response.data
            });
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }

    onFinish = (value) => {
        console.log(value);
    };



    backUpStart = () => {
        const {params} = this.props;
        const headers = {
            "auth-token": this.state.token
        }
        axios.post(process.env.REACT_APP_END_POINT + "../../../project/"+ params.id +"/backupdb/start", {},{ headers }).then((response) => {
            message.open({
                type: 'success',
                content: "BackUp degli schemi e contenuti effettuata in data odierna !",
            });
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: err.response.data.error,
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    };
    
    downloadFile = (id) => {

        const {params} = this.props;
        
        axios({
            url: process.env.REACT_APP_END_POINT + "../../../project/"+ params.id +"/backupdb/"+id._id+"/download",
            method: 'GET',
            responseType: 'blob', // Indica che stai scaricando un file
            headers: {
                "auth-token": this.state.token
            }
        })
        .then((response) => {
            if(response.data){
                console.log(response.data);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'backup.zip'); // Imposta il nome del file
                document.body.appendChild(link);
                link.click();
            }
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: err.response.data.error,
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }
   

    render(){
        const { sxCls, removeLoading, logs, form } = this.state;
        const {params,useOutletContext} = this.props;
       
        const today = new Date();              //  24 ore * 60 minuti * 60 secondi
        let nextWeek = "";
        if(form.expiration != ""){
            var options = { year: 'numeric', month: 'long', day: 'numeric' };
            nextWeek = new Date(today.getTime() + form.expiration * 60 * 60 * 1000).toLocaleDateString("it-IT", options);
            
        }

        const user = useOutletContext.project.user;
        
        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500 ">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1 overflow-y-auto scrollbar">
                    {/*<!-- Right column content -->*/}
                    <div className="first">
                       
                        <div className="px-10 py-10">
                            <h1 className='text-2xl font-bold my-2 mr-2'>BackUp Tables Project</h1>
                            <p className="text-gray-500">Gestisci i tuoi BackUp qui.</p>


                            <div className="mt-10 bg-white shadow-md mb-2 rounded item py-6 px-8 border border-grey-400 max-w-2xl">
                                <h2 className='text-xl font-bold my-2 mr-2'>Log BackUp Effettuati</h2>

                                <List
                                    dataSource={logs}
                                    renderItem={(item) => (
                                            <List.Item >
                                                <span className="relative block w-full">{item.log}</span>
                                                <Button type="link" onClick={() => this.downloadFile(item)} className="text-gray-400 hover:!text-gray-600" icon={<DownloadOutlined className="relative top-[-4px]" />} size="small"></Button>
                                            </List.Item>
                                        )}
                                    />
                            </div>

                            <div className="mt-10">
                                <h2 className='text-xl font-bold my-2 mr-2'>Crea BackUp</h2>
                                <p className="mt-2 text-sm text-gray-500">
                                    Crea il BackUp di tutti gli schema e contenuti delle tabelle del Database
                                </p>

                                <Button
                                    type="primary"
                                    icon={<PlusOutlined  className="relative top-[-4px]" />}
                                    
                                    loading={removeLoading}
                                    
                                    className="mt-6 bg-green-600 hover:!bg-green-700"
                                    //onClick={() => this.enterLoading(1)}
                                    onClick={this.backUpStart}
                                    >
                                    Crea BackUp
                                </Button>
                            </div>
                            <div className="mt-10">
                                <h2 className='text-xl font-bold my-2 mr-2'>Carica BackUp</h2>
                                <p className="mt-2 text-sm text-gray-500">
                                    Carica backup progetto
                                </p>

                                <DragAndDrop params={params} token={this.state.token} />
                            </div>
                        </div>

                        
                        
                    </div>
                </div>
                
            </>
        );
    }
}

export default withRouter(Index);
import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import TopBar from "../../components/header-bar/topbar";
import { FirstColumn, Main } from '../../components/colums';
import SliderOvers from '../../components/sliders-columns/models/slider-overs';
import DropdownButton from "../../components/dropdown-button";
import GridDragDrop from "../../components/gridDragDrop";
import SidebarComponents from '../../components/schema/sliders-columns';
import { withRouter } from '../../components/withRouter';
import { message } from 'antd';
import axios from 'axios';
import {getKeyPrivateRoute} from '../../components/PrivateRoute';
import SchemaMain from '../../components/colums/SchemaMain';

class Index extends React.Component{

    constructor(props){
        super(props);

        
        const this_ = this;

        this.state = {
            modalShow: false,
            token: getKeyPrivateRoute().token,
            user: getKeyPrivateRoute().user,
            sidebarComponentsShow: false,
            models: {},
            sidebarComponents: "",
            sxCls: {
                title: "Schema",
                collapses: [
                    {
                        title: "Models",
                        add: true,
                        addAction: (e) => {
                            //console.log("Action", e, this_);
                            this_.setState({
                                modalShow: true
                            });
                        },
                        id: null,
                        menu: [ /*
                            {
                                title: "Author",
                                link: "#author"
                            },
                            {
                                title: "Page",
                                link: ""
                            },
                            {
                                title: "Post",
                                link: ""
                            },
                            {
                                title: "SEO",
                                link: ""
                            }*/
                        ]
                    },
                    {
                        title: "Components",
                        id: null,
                        add: false,
                        menu: 'Components are pre-defined sets of fields that can be reused across models.'
                    },
                    {
                        title: "Remote",
                        id: null,
                        add: false,
                        menu: 'Remote Sources are connectors to external REST or GraphQL APIs.'
                    },
                    {
                        title: "Enumerations",
                        id: null,
                        add: false,
                        menu: 'Enumerations helps you group values within a type.'
                    }
                ]
            },
            reloadComponent: false
        }
    }

    componentDidMount(){
        //Loading all collections
        const {params} = this.props;

        const headers = {
            "auth-token": this.state.token
        }

        //axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collection/"+params.model, { headers }).then((response) => {
        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collections", { headers }).then((response) => {
            //console.log(response.data);
            response.data.forEach(el => {
                const models = this.state.sxCls.collapses[0].menu;
                if(!el.name) return;
                models[models.length] = {
                    title: el.name.charAt(0).toUpperCase() + el.name.slice(1),
                    link: "/project/"+ params.id +"/schema/model/"+el._id
                };
                this.setState(models);

                const models_ = this.state.models;
                models_[el._id] = el;
                this.setState(models_);

                
            });

            
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }


    setOpen = () => {
        this.setState({
            modalShow: false
        });
    }

    setSidbarComponentsOpen = (e) => { 
        /*
        if(e === "single-line-text"){

        }else if(e === "slug"){

        }else if(e === "numbers"){

        }else if(e === "decimal128"){

        }else if(e === "boolean"){

        }else if(e === "date"){

        }else if(e === "assets_pic"){

        }else if(e === "geo"){

        }else if(e === "array"){

        }else if(e === "ObjectId"){

        }
*/
        this.setState({
            sidebarComponentsShow: !this.state.sidebarComponentsShow,
            sidebarComponents: e
        });
    }

    sliderOversSuccess = (res) => {
        const {params} = this.props;
        const models = this.state.sxCls.collapses[0].menu;

        if(!res.name) return;
        models[models.length] = {
            title: res.name.charAt(0).toUpperCase() + res.name.slice(1),
            link: "/project/"+ params.id +"/schema/model/"+res._id
        };
        this.setState(models);

        const models_ = this.state.models;
        models_[res._id] = res;
        this.setState(models_);
    }

    sliderComponentSuccess = (res) =>{


        this.setState({
            reloadComponent: true
        });
        
    }
    forceloadStop = () => {
        this.setState({
            reloadComponent: false
        });
    }

    render(){
        const { sxCls,modalShow, sidebarComponentsShow, models, reloadComponent } = this.state;
        const {params} = this.props;
        const model = models[params.model];

        return (
            <>
                <div className="flex-initial  border-r border-grey-500 ">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1">
                    {/*<!-- Right column content -->*/}
                    <SchemaMain params={params} load={reloadComponent} forceloadStop={this.forceloadStop} sidebarComponentsShow={this.setSidbarComponentsOpen} />
                </div>
                <SliderOvers show={modalShow} setOpen={this.setOpen} success={this.sliderOversSuccess} />


                <SidebarComponents params={params} collections={this.state.models} show={sidebarComponentsShow} sidebarComponents={this.state.sidebarComponents} setOpen={() => this.setSidbarComponentsOpen(this.state.sidebarComponents)} success={this.sliderComponentSuccess} navigate={this.props.navigate} />
            </>
        );
    }
}

export default withRouter(Index);
import React from "react";
import { FirstColumn, Main } from '../../../components/colums';
import { Table, message, Upload, Badge } from 'antd';
import './style.css'
import {Link} from "react-router-dom";
import SliderRoles from '../../../components/sliders-columns/settings-roles/slider-roles';
import {
    UsergroupAddOutlined
  } from '@ant-design/icons';

  import { withRouter } from '../../../components/withRouter';
  import axios from 'axios';
  import {getKeyPrivateRoute} from '../../../components/PrivateRoute';


const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (t) => {
        console.log(t);
        return (<div className="flex items-center gap-x-3">
                    <div className="flex items-center w-full"> 
                        <div className="grow">
                            <p className="text-sm  tracking-tight text-gray-900">{t.role}</p>
                            <p className="text-xs text-gray-600">{t.desc}</p>
                        </div>
                        {/*<Link to={t.link} className="">Edit Permissions</Link>*/}
                    </div>
                </div>)
      }
    }
];

class Index extends React.Component{

    constructor(props){
        super(props);

        
        const this_ = this;
        const {params} = this.props;


        this.state = {
            token: getKeyPrivateRoute().token,
            removeLoading: false,
            modalShow: false,
            sxCls: {
                title: "Settings",
                collapses: [
                    {
                        title: "General",
                        id: null,
                        menu: [
                            {
                                title: "Project",
                                link: "/project/"+params.id+"/settings/project"
                            },
                            {
                                title: "BackUp",
                                link: "/project/"+params.id+"/settings/backup",
                            },
                            {
                                title: "Tokens Public Api",
                                link: "/project/"+params.id+"/settings/project/api-tokens",
                                active: false
                            }
                        ]
                    },
                    {
                        title: "Access",
                        id: null,
                        menu: [
                            {
                                title: "Roles & Permissions",
                                link: "/project/"+params.id+"/settings/permissions",
                                active: true
                            },{
                                title: "Team members",
                                link: "/project/"+params.id+"/settings/teams",
                            }
                        ]
                    }
                ]
            },
            dataSource: [/*
                {
                    id: 1,
                    role: {
                        role: "Admin",
                        desc: "Can manage teams and create, update projects.",
                        link: "dfuerjir4idjriesjru"
                    }
                },
                {
                    id: 2,
                    role: {
                        role: "Contributor",
                        desc: "Can create and update content.",
                        link: "ksejrhdui34hw3yeh8w"
                    }
                }
            */]
        }
    }

    onFinish = (value) => {
        console.log(value);
    };

    enterLoading = (index) => {
        this.setState({
            removeLoading: true
        });
        
    
        setTimeout(() => {
            this.setState({
                removeLoading: false
            });
        }, 6000);
    };

    setOpen = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    saveSlider = (e) => {


        const dataSource = this.state.dataSource;
        dataSource[dataSource.length] = {
            id: dataSource.length +1,
            role: {
                role: e.name,
                desc: e.desc,
                link: e.link
            }
        }

    }

    componentDidMount(){
        
        const {params} = this.props;

        const headers = {
            "auth-token": this.state.token
        }
        

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/roles",{ headers }).then((response) => {
           let arr = [];
            response.data.forEach((element, i) => {
                arr[i] = {
                    id: i,
                    role: {
                        role: element.name,
                        desc: element.desc,
                        link: element._id,
                    }
                }
            });

            this.setState({
                dataSource: arr
            });
        }).catch((err) => { console.log(err);
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }

    render(){
        const { sxCls, dataSource } = this.state;

        
        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500 ">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1 overflow-y-auto scrollbar">
                    {/*<!-- Right column content -->*/}
                    <div className="first">
                       
                        <div className="px-10 py-10">
                            <h1 className='text-2xl font-bold my-2 mr-2'>Roles & Permissions</h1>
                            <p className="text-gray-500">Configure roles to define the different groups of authorities for your project.</p>


                            <div className="mt-10">
                                <div className="flex mb-3">
                                    <p className="uppercase text-[0.875rem] tracking-wider text-gray-600 flex-none">System Roles ({dataSource.length})</p>
                                    <div className="grow"></div>
                                    <Link onClick={this.setOpen} className="flex-none text-xs text-indigo-600 hover:bg-indigo-50 rounded py-1 px-2"><UsergroupAddOutlined className="relative top-[-2px] mr-1 text-sm " />Add role</Link>
                                </div>
                                <Table loading={false} className="border-2 border-gray-300 rounded table-b" size="small" pagination={false} dataSource={[...dataSource]} columns={columns} />
                            </div>

                        </div>
                        
                    </div>
                </div>

                <SliderRoles show={this.state.modalShow} setOpen={this.setOpen} saveSlider={this.saveSlider} project={this.props.params.id} />
            </>
        );
    }
}

export default withRouter(Index);
import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import {  Outlet, useOutletContext  } from 'react-router-dom';
import SliderOvers from '../../components/sliders-columns/notifics/slider-nofifics';
import {getKeyPrivateRoute} from '../../components/PrivateRoute';
import axios from 'axios';
import { message } from 'antd';
import { withRouter } from '../withRouter';

class Index extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            notificShow: false,
            loading: false,
            project: {},
            token: getKeyPrivateRoute().token,
            user: getKeyPrivateRoute().user,
        }
    }

    componentDidMount() {
        let { id } = this.props.params;

        //Check id found
        const headers = {
            "auth-token": this.state.token
        }
        axios.get(process.env.REACT_APP_END_POINT + "/project/"+id, { headers }).then((response) => {
            if(response.data === null){
                message.open({
                    type: 'error',
                    content: "No Database present!",
                });
                this.props.navigate("/projects");

                return ;
            }

            this.setState({
                loading: true,
                project: response.data
            });
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else
            if(err.response.status === 400){
                
                message.open({
                    type: 'error',
                    content: err.response.data.message,
                });
                this.props.navigate("/projects");
            }

        });


        console.log(id);
    }

    setOpen = (e) => {
        this.setState({notificShow: !this.state.notificShow});
    }

    render(){
        const {notificShow, loading, user, project} = this.state;

        return (
            <>
                {loading ? 
                    <>
                    {/*<TopBar />*/}
                    <div className="flex h-screen ">
                        {/*<!-- Sidebar -->*/}
                        <div className="border-r border-grey-500 w-50 asidal-f">
                            {/*<!-- Sidebar content -->*/}
                            <Sidebar setOpen={this.setOpen} project={project} />
                        </div>
                        
                        {/*<!-- Main content -->*/}
                        <div className="flex-1">
                            <div className="flex h-full">
                                <Outlet context={{user, project}} />
                            </div>
                        </div>
                    </div>

                    <SliderOvers show={notificShow} setOpen={this.setOpen}  />
                    </>
                : ""}
            </>
        );
    }
}

export function useUser() {
    return useOutletContext();
}

export default withRouter(Index);
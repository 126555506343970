import React from "react";

import { FirstColumn, Main } from '../../components/colums';
import ContentMain from '../../components/colums/ContentMain';
import axios from 'axios';
import {  message } from 'antd';
import { withRouter } from '../../components/withRouter';
import {getKeyPrivateRoute} from '../../components/PrivateRoute';

class Index extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            token: getKeyPrivateRoute().token,
            reloadComponent: false,
            actionFColumn: false,
            sxCls: {
                title: "Content",
                collapses: [
                    {
                        title: "Default Values",
                        id: null,
                        menu: []
                    }
                ],
            }
        }
    }

    componentDidMount(){
        //Loading all collections
        const {params} = this.props;

        const headers = {
            "auth-token": this.state.token
        }
        const models = this.state.sxCls.collapses[0].menu;

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collections", { headers }).then((response) => {
            //console.log(response.data);
            response.data.forEach(el => {
                if(!el.name) return;

                models[models.length] = {
                    title: el.name.charAt(0).toUpperCase() + el.name.slice(1),
                    link: "/project/"+ params.id +"/content/"+el._id
                };
                this.setState(models);
            });

            
        }).catch((err) => { 
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }

    sliderComponentSuccess = (res) =>{


        this.setState({
            reloadComponent: true
        });
        
    }
    forceloadStop = () => {
        this.setState({
            reloadComponent: false
        });
    }
    actionFColumn = (e) =>{
        this.setState({
            actionFColumn: e
        });
    }
   
    render(){
        const { sxCls,reloadComponent, actionFColumn } = this.state;
        //const searchInput = useRef(null);

        return (
            <>
                <div className="flex-initial  border-r border-grey-500">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} actionFColumn={this.actionFColumn} />
                </div>
                <div className="flex-1 ">
                    {/*<!-- Right column content -->*/}
                    <ContentMain load={reloadComponent} forceloadStop={this.forceloadStop} actionFColumn={actionFColumn} />
                </div>
            </>
        );
    }
}

export default withRouter(Index);
import React from 'react';
import DropdownButton from "../../dropdown-button";
import GridDragDrop from "../../gridDragDrop";
import axios from 'axios';
import { message } from 'antd';
import {getKeyPrivateRoute} from '../../PrivateRoute';
import CardSchema from '../../schema/cardList';
import { withRouter } from '../../withRouter';

import { LinkSimple, NumberCircleEight, Pi, ToggleLeft, Paperclip, MapPin, BracketsCurly, Link, Calendar,DotsThree } from "@phosphor-icons/react";
import {
    EditOutlined,
    DeleteOutlined,
    CopyOutlined
  } from '@ant-design/icons';

class SchemaMain extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            fields: [],
            token: getKeyPrivateRoute().token,
            modelId: '',
            model_: {},
            string: [
                {
                    type: "single-line-text",
                    title: "String",
                    description: "Headings and title"
                },
                {
                    type: "slug",
                    title: "Slug",
                    description: "Url indentifier"
                },
            ],
            number: [
                {
                    type: "Number",
                    title: "Number",
                    description: "ID, quantity, etc."
                },
            ], 
            float: [
                {
                    type: "decimal128",
                    title: "Decimal",
                    description: "Rating, price, etc."
                },
            ],
            boolean: [
                {
                    type: "Boolean",
                    title: "Boolean",
                    description: "True or false"
                },
            ],
            date: [
                {
                    type: "Date",
                    title: "Date",
                    description: "Calendar date picker w/time"
                },
            ],
            assets: [
                {
                    type: "assets_pic",
                    title: "Asset picker",
                    description: "Support any file type"
                },
            ],
            location: [
                {
                    type: "geo",
                    title: "Map",
                    description: "Geo coordinates"
                },
            ],
            array: [
                {
                    type: "Array",
                    title: "JSON editor",
                    description: "Arbitary JSON structures"
                },
            ],
            relation:[
                {
                    type: "ObjectId",
                    title: "Reference",
                    description: "Content relationship table"
                }
            ]
        }

    }

    componentDidMount(){
        const { load, params} = this.props;
        
        this.setState({
            modelId: params.model,
        });
        
        this.loading();
    }

    componentDidUpdate = () => {

        if(this.props.load){
            this.props.forceloadStop();
            this.state.modelId = null;
            this.forceUpdate();
        }

        this.loading();
        
    }

    loading = async () => {
        //Loading all collections
        const {params} = this.props;
        const { fields, model_, modelId } = this.state;

        const headers = {
            "auth-token": this.state.token
        }
        

        if(modelId === params.model) return;

        this.setState({
            modelId: params.model,
            //fields: []
        });

        await axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collection/"+params.model, { headers }).then((response) => {
            const el = response.data;

            this.setState({
                model_: el,
            });

            fields[params.model] = [];

            Object.keys(el.schema).map((el2, index) => {
                let tileds = [];
                
                if(Object.values(el.schema)[index]){
                    Object.keys(Object.values(el.schema)[index]).map(element => {
                        if(element === "type"){
                            tileds.push(Object.values(el.schema)[index].type);
                        }else if(element === "required"){
                            if(Object.values(el.schema)[index].required === true){
                                tileds.push("Required");
                            }
                        }else{
                            tileds.push("undefined");
                        }
                    });
                    
                }
                
                fields[params.model][index] = {
                    id: index,
                    title: el2.charAt(0).toUpperCase()+el2.substring(1),
                    type: tileds[0],
                    tags: tileds//Object.values(model.schema)[index]
                }
               
            })
            
            
            
        }).catch((err) => { 
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: "access error!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });

        this.setState(fields);
    }

    removeModel = async (e) => {

        const {params } = this.props;

        
        const headers = {
            "auth-token": this.state.token
        }

        await axios.delete(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collection/"+params.model, { headers }).then((response) => {
            message.open({
                type: 'success',
                content: "Modello rimosso!",
            });

            this.props.navigate("/project/"+ params.id + "/schema");
        }).catch((err) => { 
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: "access error!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });

    }

    actionCard = async (e) => {

        const {params } = this.props;
        const {fields} = this.state;
        
        
        
        const headers = {
            "auth-token": this.state.token
        }

        await axios.delete(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collection/"+params.model+"/"+e.title.toLowerCase(), { headers }).then((response) => {
            message.open({
                type: 'success',
                content: "Field rimosso!",
            });

            if(e.id){
                delete fields[params.model][e.id];
            }
    
            this.forceUpdate()            
        }).catch((err) => { 
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: "access error!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }

    render(){

        const {params, load} = this.props;
        const {fields, model_, string,number, float, boolean, date, assets, location, array, relation} = this.state;


        if(Object.keys(model_).length === 0){

            return (<>Loading...</>)
        }

        
        return (
            <div className="first">
                <div className="p-3 py-2 px-4 flex justify-start items-center border-b border-grey-500">
                    <h1 className='text-2xl font-bold my-2 mr-2'>{ model_.name.charAt(0).toUpperCase()+model_.name.substring(1)}</h1>
                    <span className="text-gray-500">#{model_._id}</span>
                    <DropdownButton 
                        buttonClassName="inline-flex w-full justify-center rounded-md ml-2 px-1 py-1 text-default font-medium text-gray-500 hover:!bg-gray-100 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" 
                        dropdownClassName="z-50 absolute right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        childrenButton={<DotsThree size={30} weight="bold"/>} 
                        dropList={[
                            {
                                label: <a href="https://www.antgroup.com">Edit</a>,
                                key: '0',
                                icon: <EditOutlined />
                            },
                            {
                                label: <a href="https://www.aliyun.com">Duplicate</a>,
                                key: '1',
                                icon: <CopyOutlined />
                            },
                            {
                                type: 'divider',
                            },
                            {
                                label: 'Remove',
                                icon: <DeleteOutlined />,
                                danger: true,
                                key: '3',
                                onClick: this.removeModel
                            }
                        ]}
                        chevron={false} 
                    />

                </div>

                <div className="flex ">
                    <div className="w-full p-3 py-2 px-4  overflow-auto scrollbar h-[calc(100vh_-_65px)] ">
                        <div className="max-w-[720px] mx-auto mt-10">
                            {/*<GridDragDrop list={fields[params.model]} />*/}
                            {fields[params.model] !== undefined ? 
                                fields[params.model].map((item, i) => <CardSchema key={i} item={item} action={this.actionCard} />)
                                : <></>
                            }
                        </div>
                    </div>
                    <div className="w-96 border-l border-grey-500 p-3 py-2 px-4  overflow-auto scrollbar h-[calc(100vh_-_65px)] bg-indigo-50/30">
                        <h3 className="font-medium pt-3">Add Fields</h3>
                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">String</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    string.map((item, i) => {
                                        
                                        return (<div key={i} onClick={() => this.props.sidebarComponentsShow(item.type)} className="shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "single-line-text" ? 
                                                                <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">S</div>
                                                            : item.type === "slug" ? 
                                                                <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <LinkSimple size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>
                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">Integer</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    number.map((item, i) => {
                                        return (<div key={i} onClick={() => this.props.sidebarComponentsShow(item.type)}  className=" shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "Number" ? 
                                                                <div className="mr-4 bg-orange-50 text-orange-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <NumberCircleEight size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs ">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700 ">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>

                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">FLOAT</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    float.map((item, i) => {
                                        
                                        return (<div key={i} onClick={() => this.props.sidebarComponentsShow(item.type)}  className=" shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "decimal128" ? 
                                                                <div className="mr-4 bg-amber-50 text-amber-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <Pi size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs ">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700 ">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>
                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">BOOLEAN</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    boolean.map((item, i) => {
                                        
                                        return (<div key={i} onClick={() => this.props.sidebarComponentsShow(item.type)}  className=" shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "Boolean" ? 
                                                                <div className="mr-4 bg-rose-50 text-rose-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <ToggleLeft size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs ">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700 ">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>
                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">DATE</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    date.map((item, i) => {
                                        
                                        return (<div key={i} onClick={() => this.props.sidebarComponentsShow(item.type)}  className=" shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "Date" ? 
                                                                <div className="mr-4 bg-blue-50 text-blue-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <Calendar size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs ">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700 ">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>
                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">ASSETS</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    assets.map((item, i) => {
                                        
                                        return (<div key={i} onClick={() => this.props.sidebarComponentsShow(item.type)}  className=" shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "assets_pic" ? 
                                                                <div className="mr-4 bg-fuchsia-50 text-fuchsia-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <Paperclip size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs ">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700 ">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>
                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">MAP</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    location.map((item, i) => {
                                        
                                        return (<div key={i} onClick={() => this.props.sidebarComponentsShow(item.type)}  className=" shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "geo" ? 
                                                                <div className="mr-4 bg-indigo-50 text-indigo-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <MapPin size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs ">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700 ">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>
                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">ARRAY</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    array.map((item, i) => {
                                        
                                        return (<div key={i} onClick={() => this.props.sidebarComponentsShow(item.type)}  className=" shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "Array" ? 
                                                                <div className="mr-4 bg-pink-50 text-pink-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <BracketsCurly size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs ">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700 ">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>
                        <div className="mt-2">
                            <span className="text-sm uppercase tracking-wide">RELATION</span>

                            <div className="grid gap-1 mt-4">
                                {
                                    relation.map((item, i) => {
                                        
                                        return (<div key={i}  onClick={() => this.props.sidebarComponentsShow(item.type)} className=" shadow-md mb-2 rounded item w-full flex p-3 border border-grey-400 bg-white cursor-pointer select-none" >
                                                    <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                                                        <div className="color-badge"></div>
                                                        {
                                                            item.type === "ObjectId" ? 
                                                                <div className="mr-4 bg-purple-50 text-purple-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">
                                                                    <Link size={25} />
                                                                </div>
                                                            : <div className="mr-4 bg-emerald-50 text-emerald-800 w-10 h-10 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                                                        }
                                                        <div className="mr-2 grid grid-rows-2">
                                                            <span className="font-normal text-xs ">
                                                                {item.title}
                                                            </span>
                                                            <div className="flex font-normal text-xs text-gray-700 ">
                                                            {
                                                                item.description
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(SchemaMain);
import React, {  useRef, useState } from "react";
import { useAutoScroll } from "react-tiny-autoscroll";
import { Draggable, moveItems, useDraggable, useDraggableContext } from "react-tiny-dnd";
import { Button } from 'antd';

function hashCode(str) {
    let hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function intToRGB(i) {
    const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "#" + "00000".substring(0, 6 - c.length) + c;
}

const hashToColor = (hash) => intToRGB(hashCode(hash));

const buildItem = (n) => {
    const id = String(n + 1);
    return {
        id,
        color: hashToColor(id.repeat(10)),
    };
};

const defaultItems = Array(100).fill(null).map((_, i) => buildItem(i));

const def_element = ['id', 'createat', 'updateat']

const Item = ({
    id,
    color: backgroundColor,
    listeners,
    item,
    isDragging,
    handleDelete,
}) => {
    const index = Number(id);
    const opacity = isDragging ? 0 : 1;

    //default element def_element.includes(item.title.toLowerCase())
    return (
        <div className={[" bg-white shadow-md mb-2 rounded item w-full flex py-4 px-3 border border-grey-400 group/item", def_element.includes(item.title.toLowerCase()) ? "!bg-gray-50": ""].join(" ")} style={{  opacity, backgroundColor: "white" }}>
            
            {
                def_element.includes(item.title.toLowerCase()) ? 
                    <div className="select-none center flex justify-center items-center mr-2 opacity-0">
                        <span className="material-symbols-outlined">drag_indicator</span>
                    </div>
                :
                    <div className="select-none cursor-grab center flex justify-center items-center mr-2" {...listeners}>
                        <span className="material-symbols-outlined">drag_indicator</span>
                    </div>
            }
            
            <div style={{ display: "flex", flex: 1, alignItems: "center"}}>
                <div className="color-badge" style={{ backgroundColor }} {...listeners}></div>
                {
                    item.type === "single-line-text" ? 
                        <div className="mr-4 bg-emerald-50 text-emerald-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                    : item.type === "Date" ? 
                        <div className="mr-4 bg-blue-50 text-blue-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <span className="material-symbols-outlined">calendar_today</span>
                        </div>
                    : item.type === "relation" ? 
                        <div className="mr-4 bg-violet-50 text-violet-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">
                            <span className="material-symbols-outlined rotate-45">link</span>
                        </div>
                    : <div className="mr-4 bg-emerald-50 text-emerald-800 w-12 h-12 text-3xl font-normal rounded text-center center flex justify-center items-center">T</div>
                }
                <div className="mr-2 grid grid-rows-2">
                    <span className="font-medium">
                        {item.title}
                    </span>
                    <div className="flex">
                    {
                        item.tags.map((tag, i) => {
                            
                            return <span key={i} className="mr-1 font-medium bg-slate-100 rounded text-slate-500 text-xs relative center flex justify-center items-center px-2">{tag}</span>
                        })
                    }
                    {
                        def_element.includes(item.title.toLowerCase()) ? 
                            <span className="mr-1 font-medium bg-slate-100 rounded text-slate-500 text-xs relative center flex justify-center items-center px-2">System Field</span>
                        : ""
                    }
                    {
                        def_element.includes(item.title.toLowerCase()) ? 
                            "" 
                        : 
                            <Button onClick={() => handleDelete?.()} type="primary" className="hover:bg-red-900 hidden group-hover/item:flex" danger size={'small'}>Delete field</Button>
                    }
                    </div>
                </div>
            </div>
        </div>
    );
}

const DraggableItem = ({
    index,
    context,
    item,
    handleDelete,
}) => {
    const { listeners, isDragging } = useDraggable(context, index);

    return (
        <Draggable
            context={context}
            key={item.id}
            index={index}
            dividerClassName=""
        {...{
            preview: <div style={{ width: 500 }}>
                <Item id={item.id} color={item.color} listeners={listeners} isDragging={false} item={item} />
            </div>
        }}
        >
            <Item id={item.id} color={item.color} listeners={listeners} isDragging={isDragging} item={item} handleDelete={() => handleDelete(item.id)} />
        </Draggable>
    );
};

function App(props) {
    const [items, setItems] = useState(props.list);

    const onDrop = (dragIndex, overIndex) => {
        const nextItems = moveItems(items, dragIndex, overIndex);
        setItems(nextItems);
    };

    const containerRef = useRef(null);

    const context = useDraggableContext({
        onDrop,
    });

    useAutoScroll({ containerRef, skip: !context.isDragging });

    const handleDelete = (id) => setItems(items.filter((item) => item.id !== id))

    return (
        <div style={{  }}>
            <div ref={containerRef} className="">
                {items.map((item, i) => {
                    return (
                        <DraggableItem context={context} key={item.id} index={i} item={item} handleDelete={handleDelete} />
                    );
                })}
            </div>
        </div>
    );
}

export default App;
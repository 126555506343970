import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Alert, Space } from 'antd';

export default function Example(props) {
  //const [open, setOpen] = useState(props.show)
  
  
  
  localStorage.setItem("nt-r", 2)
  if(props.show){
    localStorage.setItem("nt", "2")
  }


  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  

                  {/* Modal Window */}
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-6 py-6 flex">
                      <div className="w-full">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Notifiche
                        </Dialog.Title>
                      </div>
                      <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex center">
                                <button
                                    type="button"
                                    className="rounded-md text-gray-400 hover:text-gray-500  focus:outline-none"
                                    onClick={props.setOpen}
                                >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </Transition.Child>
                    </div>
                    <div className="relative mt-0 flex-1 px-0 sm:px-0">
                        
                        <div className="px-6">
                            <div className="">
                                    <div className="sm:overflow-hidden sm:rounded-md">
                                        <div className="space-y-6 bg-white ">
                                        <Space
                                            direction="vertical"
                                            style={{
                                            width: '100%',
                                            }}
                                        >
                                            <div>
                                                <p className="py-2">2 Sett. 2023</p>
                                                <Alert
                                                    message="V.1.6.89 BugFix"
                                                    description={
                                                      <span>Bug Fix eliminazione elemento dalle tabelle di contenuto.</span>
                                                    }
                                                    type="info"
                                                    showIcon
                                                    className='ml-2 mb-2'
                                                />
                                                <p className="py-2">1 Sett. 2023</p>
                                                <Alert
                                                    message="V.1.6.89 Member Team"
                                                    description={
                                                      <span>Aggiunta dell'impostazione member team e permessi dell'utente, è possibile visuallizzare questa scheda andando su <b>Project Settings &gt; Access &gt; Team members</b>.</span>
                                                    }
                                                    type="info"
                                                    showIcon
                                                    className='ml-2 mb-2'
                                                />
                                                <p className="py-2">30 Agos. 2023</p>
                                                <Alert
                                                    message="V.1.6.48 Mmbers Team"
                                                    description={
                                                      <span>Inizio Implementazione aggiunta del members team più permessi del progetto.</span>
                                                    }
                                                    type="info"
                                                    showIcon
                                                    className='ml-2 mb-2'
                                                />
                                                <Alert
                                                    message="V.1.6.25 Bug Fix"
                                                    description={
                                                      <span>Fix vari, sistemazione tabella contenuti per il relazionamento tra collections, impostazione nella modifica del documento il valore del riferimento alla relazione ID.</span>
                                                    }
                                                    type="info"
                                                    showIcon
                                                    className='ml-2 mb-2'
                                                />
                                                <p className="py-2">29 Agos. 2023</p>
                                                <Alert
                                                    message="V.1.5.66 Aggiunta BackUp"
                                                    description={
                                                      <span>Aggiunta dell'impostazione del BackUp per settare o recuperare informazioni relative agli schemi e contenuti, è possibile visualizzare questa scheda andando su <b>Project Settings/BackUp</b>.</span>
                                                    }
                                                    type="info"
                                                    showIcon
                                                    className='ml-2 mb-2'
                                                />
                                            </div>
                                        </Space>
                                        </div>
                                    </div>
                                </div>
                            </div>


                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

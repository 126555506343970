import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Select, message,ConfigProvider } from 'antd';
import axios from 'axios';
import {getKeyPrivateRoute} from '../../PrivateRoute';


export default function Example(props) {
    const [messageApi, contextHolder] = message.useMessage();

  //const [open, setOpen] = useState(props.show)
    
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [roles, setRoles] = useState([]);
    
    const id = props.projectID
    const headers = {
        "auth-token": getKeyPrivateRoute().token
    }


    const laod = () => {
        
        if(roles.length > 0) return;
        let r = [];
        
        axios.get(process.env.REACT_APP_END_POINT + "/project/"+id + "/roles", { headers }).then((response) => {
            
            response.data.map((data, i) => {
                
                r[i] = {
                    value: data._id,
                    label: data.name
                }
                setRoles(r);
            });
            
        }).catch((err) => {
            console.log(err);
            message.open({
                type: 'error',
                content: "Errore: 500!",
            });
        });
    }
    


    const onSubmit = (e) => {
        e.preventDefault();
       
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if(name.match(validRegex) <= 0){
            messageApi.open({
                type: 'warning',
                content: 'Ricorda di inserire una email valida',
            });
            return;
        }
        if(role.length === 0){
            messageApi.open({
                type: 'warning',
                content: 'Ricorda di inserire un Ruolo',
            });
            return;
        }

        //Check email details
        let user = {};
        
        axios.put(process.env.REACT_APP_END_POINT + "/project/"+id + "/teams/add", {
            email: name,
            roles: role
        },{ headers }).then((response) => {
            user = {
                name: "Invite",
                surname: "Pending",
                avatar: "https://xsgames.co/randomusers/assets/avatars/pixel/47.jpg",
                email: name
            };
            message.open({
                type: 'success',
                content: "Invito inviato!",
            });

            props.saveSlider({
                user: user,
                role: role
            });
    
            props.setOpen();
        }).catch((err) => {
            console.log(err);
            message.open({
                type: 'error',
                content: "Errore: 500!",
            });
        });

        
    }

    const handleInputChange = (e) => {
        //const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        const name = e.currentTarget.name;

        if(name == "email"){
            setName(value);
        }

    }

    const onChangeDpworn = (value) => {
        console.log(`selected ${value}`);

        setRole(value);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    laod();
    
    return (
        <Transition.Root show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
            <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {contextHolder}

            <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    

                    {/* Modal Window */}
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-6 py-6 flex">
                        <div className="w-full">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Add Members
                            </Dialog.Title>
                        </div>
                        <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-500"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="flex center">
                                    <button
                                        type="button"
                                        className="rounded-md text-gray-400 hover:text-gray-500  focus:outline-none"
                                        onClick={props.setOpen}
                                    >
                                        <span className="sr-only">Close panel</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                        </div>
                        <div className="relative mt-0 flex-1 px-0 sm:px-0">
                            
                            <div className="">
                                <div className="">
                                    <form onSubmit={onSubmit}>
                                        <div className="sm:overflow-hidden sm:rounded-md">
                                            <div className="space-y-6 bg-white ">
                                                <div className="grid">
                                                    <div className="px-4 py-5 sm:p-6 col-span-3 sm:col-span-2 grid grid-cols-6 grid-flow-row-dense content-center items-center">
                                                        <label htmlFor="company-website" className="flex justify-start items-center h-full col-span-1 block text-sm font-medium leading-6 text-gray-900">
                                                            Email
                                                        </label>
                                                        <div className="mt-0 flex rounded-md shadow-sm col-span-5">
                                                            <input
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                autoComplete="given-name"
                                                                onChange={handleInputChange}
                                                                className=" block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="px-4 py-5 sm:p-6 col-span-3 sm:col-span-2 grid grid-cols-6 grid-flow-row-dense content-center items-center">
                                                        <label htmlFor="company-website" className="flex justify-start items-center h-full col-span-1 block text-sm font-medium leading-6 text-gray-900">
                                                            Role
                                                        </label>
                                                        <div className="mt-0 flex rounded-md shadow-sm col-span-5">
                                                            <Select
                                                                showSearch
                                                                className='w-full'
                                                                placeholder="Select a person"
                                                                optionFilterProp="children"
                                                                onChange={onChangeDpworn}
                                                                onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={roles}
                                                            />
                                                        </div>
                                                    </div>

                                                    
                                                </div>

                                                </div>
                                                <div className=" px-4 py-3 text-right sm:px-6">
                                                    <button
                                                        type="submit"
                                                        className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                        </div>
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
}

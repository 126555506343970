import React from "react";
import { FirstColumn, Main } from '../../components/colums';
import SliderOvers from '../../components/sliders-columns/models/slider-overs';
import { withRouter } from '../../components/withRouter';
import { message } from 'antd';
import axios from 'axios';
import {getKeyPrivateRoute} from '../../components/PrivateRoute';
import SchemaMain from '../../components/colums/SchemaIndex';
import { createFromIconfontCN } from '@ant-design/icons';
import {Link} from "react-router-dom";

class Index extends React.Component{

    constructor(props){
        super(props);

        
        const this_ = this;

        this.state = {
            modalShow: false,
            token: getKeyPrivateRoute().token,
            user: getKeyPrivateRoute().user,
            sidebarComponentsShow: false,
            models: {},
            sxCls: {
                title: "Contenuto",
                collapses: [
                    {
                        title: "Default view",
                        add: false,
                        addAction: (e) => {
                            console.log("Action", e, this_);
                            this_.setState({
                                modalShow: true
                            });
                        },
                        id: null,
                        menu: [ ]
                    }
                ]
            }
        }
    }

    componentDidMount(){
        //Loading all collections
        const {params} = this.props;

        const headers = {
            "auth-token": this.state.token
        }
        const models = this.state.sxCls.collapses[0].menu;

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collections", { headers }).then((response) => {
            //console.log(response.data);
            response.data.forEach(el => {
                if(!el.name) return;
                models[models.length] = {
                    title: el.name.charAt(0).toUpperCase() + el.name.slice(1),
                    link: "/project/"+ params.id +"/content/"+el._id
                };
                this.setState(models);

                const models_ = this.state.models;
                models_[el._id] = el;
                this.setState(models_);

                
            });

            
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }

    s

    render(){
        const { sxCls,modalShow, sidebarComponentsShow } = this.state;
        const IconFont = createFromIconfontCN({
            scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
          });
        
        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500 ">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1">
                    {/*<!-- Right column content -->*/}
                    <div className="first">

                        <div className="flex ">
                            <div className="w-full max-w-[996px] py-14 px-14  overflow-auto scrollbar h-[calc(100vh_-_65px)] ">
                                <h1 className="text-2xl font-medium my-2">Contenuto</h1>
                                <p className="mt-6 text-slate-500 text-[.9rem]">Gestisci il tuo contenuto.</p>
                                <Link to="" className="block my-6 text-[.8rem] text-indigo-500">Learn more <IconFont type="icon-tuichu" className="relative top-[-3px]"/></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <SliderOvers show={modalShow} setOpen={this.setOpen} success={this.sliderOversSuccess}  />
            </>
        );
    }
}

export default withRouter(Index);
import React from "react";
import { FirstColumn, Main } from '../../../components/colums';
import { List, Checkbox, Collapse, Divider,Button } from 'antd';
import './style.css'
import {Link} from "react-router-dom";
import { withRouter } from '../../../components/withRouter';
import {
    UsergroupAddOutlined
  } from '@ant-design/icons';


const dataSource = [
    {
        id: 1,
        role: {
            role: "Admin",
            desc: "Can manage teams and create, update projects."
        }
    },
    {
        id: 2,
        role: {
            role: "Contributor",
            desc: "Can create and update content."
        }
    }
];
  
const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (t) => (<div className="flex items-center gap-x-3">
                        <div className="flex items-center w-full"> 
                            <div className="grow">
                                <p className="text-sm  tracking-tight text-gray-900">{t.role}</p>
                                <p className="text-xs text-gray-600">{t.desc}</p>
                            </div>
                            <Link to="#" className="">Edit Permissions</Link>
                        </div>
                    </div>)
    }
];

class Index extends React.Component{

    constructor(props){
        super(props);

        const {params} = this.props;        
        const this_ = this;

        this.state = {
            removeLoading: false,
            sxCls: {
                title: "Settings",
                collapses: [
                    {
                        title: "General",
                        id: null,
                        menu: [
                            {
                                title: "Project",
                                link: "/project/"+params.id+"/settings/project"
                            },
                            {
                                title: "BackUp",
                                link: "/project/"+params.id+"/settings/backup",
                            },
                            {
                                title: "Tokens Public Api",
                                link: "/project/"+params.id+"/settings/project/api-tokens",
                                active: false
                            }
                        ]
                    },
                    {
                        title: "Access",
                        id: null,
                        menu: [
                            {
                                title: "Roles & Permissions",
                                link: "/project/"+params.id+"/settings/permissions",
                                active: true
                            },{
                                title: "Team members",
                                link: "/project/"+params.id+"/settings/teams",
                            }
                        ]
                    }
                ]
            }
        }
    }

    onFinish = (value) => {
        console.log(value);
    };

    enterLoading = (index) => {
        this.setState({
            removeLoading: true
        });
        
    
        setTimeout(() => {
            this.setState({
                removeLoading: false
            });
        }, 6000);
    };

    onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    render(){
        const { sxCls, removeLoading } = this.state;
        const { Panel } = Collapse;
        const data = [
            //'Create', 'Read', 'Delete', 'Publish', 'Update'
            {
                color: "indigo",
                title: "Create",
                desc: ["all models", "for all locales"]
            },
            {
                color: "sky",
                title: "Read",
                desc: "all models for all locales"
            },
            {
                color: "red",
                title: "Delete",
                desc: "all models for all locales"
            },
            {
                color: "green",
                title: "Publish",
                desc: "all models for all locales"
            },
            {
                color: "yellow",
                title: "Update",
                desc: "all models for all locales"
            }
        ];

        const dataCheck = [
            "Change the name, picture and description of a project",
            "Create new roles",
            "Update existing roles",
            "Delete an existing role",
            "Invite a user into an existing project",
            "Remove a user from an existing project",
            "Assign a role to a user",
            "Create new environment"
        ]
        
        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500 ">
                    {/*<!-- Left column content -->*/}
                    <FirstColumn title={sxCls.title} menu_list={sxCls.collapses} />
                </div>
                <div className="flex-1 overflow-y-auto scrollbar">
                    {/*<!-- Right column content -->*/}
                    <div className="first">
                       
                        <div className="px-10 py-10">
                            <h1 className='text-lg font-normal my-2 mr-2 text-gray-600'>Roles & Permissions / asd</h1>


                            <div className="mt-10">
                                <Collapse className="collapse-gdzd" defaultActiveKey={['1']} >
                                    <Panel header={<p className="text-bolder">Content permissions</p>} key="1">
                                        <List
                                            
                                            dataSource={data}
                                            renderItem={item => {

                                                return (
                                                    <List.Item className="!px-4">
                                                        <span className={["bg-" + item.color + "-400/[.2]", "rounded", "px-2", "py-1", "text-xs", "font-normal", "text-" + item.color + "-600"].join(" ")}>{item.title}</span> {Object.values(item.desc).map(itm => (itm))}
                                                    </List.Item>
                                                );
                                            }}
                                        />
                                    </Panel>
                                </Collapse>
                                <Divider orientation="left"></Divider>
                                <Collapse className="collapse-gdzd" defaultActiveKey={['2']} >
                                    <Panel header={<p className="text-bolder">Managment API permissions</p>} key="2">
                                        <List
                                            
                                            dataSource={dataCheck}
                                            renderItem={(item, index) => {

                                                return (
                                                    <List.Item className="!px-4">
                                                        <Checkbox onChange={this.onChange} name={index} checked>{item}</Checkbox>
                                                    </List.Item>
                                                );
                                            }}
                                        />
                                    </Panel>
                                </Collapse>
                            </div>

                            <Divider className="mt-10" orientation="left"></Divider>
                            <div >
                                <p>Remove Role</p>
                                <p className="text-xs text-gray-600">Processo irreversibile, una volta eliminato questo ruolo non potrai più riutilizzarlo.</p>
                                <Button className="mt-4 bg-red-600 hover:bg-red-500 border-none text-white hover:!text-white font-medium">Remove Role</Button>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Index);
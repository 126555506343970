import React from 'react';
// Importa il file CSS di base di CodeMirror
//import 'codemirror/lib/codemirror.css';
// Importa il tema desiderato (ad esempio, 'material' in questo caso)
//import 'codemirror/theme/material.css';
//import 'codemirror/theme/eclipse.css';
//import 'codemirror-graphql/mode';
//import 'codemirror/mode/javascript/javascript';
import CodeMirror from '@uiw/react-codemirror';
import {githubLight} from '@uiw/codemirror-theme-github';

import { loadLanguage, langNames, langs } from '@uiw/codemirror-extensions-langs';
//import { StreamLanguage } from '@codemirror/language';
import { Tree, Button, Input,Select, message, Tooltip,Alert} from 'antd';
import { Copy } from "@phosphor-icons/react";
import { withRouter } from '../../components/withRouter';
import axios from 'axios';
import {getKeyPrivateRoute} from '../../components/PrivateRoute';
import {
    PlayCircleOutlined,
    LoadingOutlined
  } from '@ant-design/icons';
  import Marquee from 'react-fast-marquee';

  

class Playground extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            token: getKeyPrivateRoute().token,
            treeData:{
                title: 'Query',
                key: 'query',
                children: []
            },
            treeDataMutation: {
                title: 'Mutation',
                key: 'mutation',
                children: []
            },
            codeHeaders: {},
            code: {
                query:{}
            },
            result: {},
            expandedKeys: ['query'],
            checkedKeys:[''],
            selectedKeys: [],
            autoExpandParent: true,
            codemirrorHeight: "200px",
            tokensProject: [
                { value: 'default', label: 'Select a private key' },
            ],
            codeLoading: false
        }
    }

    componentDidMount = () =>{
        const {params} = this.props;
        const {codeHeaders} = this.state;
        

        codeHeaders["cache-ctr"] = params.id
        this.setState(codeHeaders);

        this.setState({
            codemirrorHeight: (( document.body.offsetHeight /2) )  + "px",
        });
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        document.querySelector('#codemir--23erf').style.width= document.querySelector('#codemir--23erf').offsetWidth - 50 + "px"

        //get token list
        const headers = {
            "auth-token": this.state.token
        }

        
        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/collections", { headers }).then((response) => {
            const tc = this.state.treeData.children;
            const tm = this.state.treeDataMutation.children;
            //add name (Many| ById)
            let tc_ = [];
            let tcbyid_ = [];

            let i = 0;
            response.data.forEach((coll) => {
                if(coll.name){
                    let childrens = [];
                    let childrens_id = [];
                    const schema = coll.schema;
                    
                    Object.keys(schema).forEach((children, n) => {
                        childrens[n] = {
                            title: children,
                            key: coll.name+ "sMany:" + children
                        }
                        childrens_id[n] = {
                            title: (children === "ID" ? "ID*":  children),
                            key: coll.name+ "sById:" + children
                        }
                    });

                    tc_[i] = {
                        title: coll.name+ "sMany",
                        key:coll.name+ "sMany",
                        children: childrens
                    }
                    tcbyid_[i] = {
                        title: coll.name+ "sById",
                        key:coll.name+ "sById",
                        children: childrens_id
                    }
                    

                    tm[i] = {
                        title: coll.name+ "sMany",
                        key:coll.name+ "sMany",
                        children: childrens
                    }
                    i++;
                }
            });

            const conc = tc_.concat(tcbyid_);
            
            this.setState(prevState => ({
                treeData: {
                  ...prevState.treeData,
                  children: conc.concat(prevState.treeData.children)
                }
              }));
        }).catch((err) => {
            
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: err.response.data,
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });

        axios.get(process.env.REACT_APP_END_POINT + "/project/"+ params.id +"/tokens", { headers }).then((response) => {
            
            response.data.forEach(element => {
                this.setState(prevState => ({
                    tokensProject: [...prevState.tokensProject, {value: element.token, label: element.name}]
                }));
            });

            //this.forceUpdate()
        }).catch((err) => {
            if(err.code === "ERR_NETWORK"){
                message.open({
                    type: 'error',
                    content: "Nessuna connessione con il server!",
                });
            }else if(err.code === "ERR_BAD_REQUEST"){
                message.open({
                    type: 'error',
                    content: err.response.data,
                });
            }else{

                message.open({
                    type: 'error',
                    content: err.response,
                });
            }
            
        });
    }
    
    resize() {
        this.setState({codemirrorHeight:(( document.body.offsetHeight /2) ) + "px"});
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    changeCode = (val) => {
        this.setState({
            code: JSON.parse(val)
        });

    }

    startClick = (e)=>{
        const {code,codeHeaders } = this.state;
        e.preventDefault();
        if(code.length === 0) return;

        this.setState({
            codeLoading: true
        });

        
        axios.post(process.env.REACT_APP_END_POINT_API, code, {headers: codeHeaders} ).then((response) => {
            
           this.setState({
            result: response.data,
            codeLoading: false
           });
        }).catch((err) => {
            this.setState({
                result: err.response.data,
                codeLoading: false
            });
            
        });

    }

    onExpand = (expandedKeysValue) => {
        //console.log('onExpand', expandedKeysValue);
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        
        this.setState({
            expandedKeys: expandedKeysValue,
            autoExpandParent: false
        });
    };

    onCheck = (checkedKeysValue) => {
        const queryObj = { query: {} };
        
        checkedKeysValue.forEach(item => {
          const [key, value] = item.split(':');
      
            if (!queryObj.query[key]) {
                queryObj.query[key] = [];
            }
            if (value) {
                queryObj.query[key].push(value);
            }
        });
      
        // Rimuoviamo le chiavi vuote dall'oggetto queryObj
        Object.keys(queryObj.query).forEach(key => {
            if (queryObj.query[key].length === 0) {
                delete queryObj.query[key];
            }
        });

        this.setState({
            code: queryObj,
            checkedKeys: checkedKeysValue,
        });
    };

    onSelect = (selectedKeysValue, info) => {
        console.log('onSelect', info);
        this.setState({
            selectedKeys: selectedKeysValue
        });
    };

    keyApiground = (e) => {
        const {codeHeaders} = this.state;
        
        if(e === "default"){
            delete codeHeaders["auth-api-token"]
        }else{
            codeHeaders["auth-api-token"] = e
        }

        this.setState(codeHeaders);
    }

    render(){

        const {code,codeHeaders,  result, expandedKeys, autoExpandParent, selectedKeys, treeData, treeDataMutation,checkedKeys, codemirrorHeight, tokensProject, codeLoading} = this.state;

        
        return (
            <>
                <div className="flex-initial w-64 border-r border-grey-500">
                    {/*<!-- Left column content -->*/}
                    <div className="w-50">
                        <div>
                            <div className='px-2 py-4'>
                                <h2>Query</h2>
                            </div>
                            <Tree
                                checkable
                                onExpand={this.onExpand}
                                expandedKeys={expandedKeys}
                                autoExpandParent={autoExpandParent}
                                onCheck={this.onCheck}
                                checkedKeys={checkedKeys}
                                onSelect={this.onSelect}
                                selectedKeys={selectedKeys}
                                treeData={[treeData]}
                            />
                        </div>

                        <div>
                            <div className='px-2 py-4'>
                                <h2>Mutation</h2>
                            </div>
                            <Tree
                                checkable
                                onExpand={this.onExpand}
                                expandedKeys={expandedKeys}
                                autoExpandParent={autoExpandParent}
                                onCheck={this.onCheck}
                                checkedKeys={checkedKeys}
                                onSelect={this.onSelect}
                                selectedKeys={selectedKeys}
                                treeData={[treeDataMutation]}
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full block'>
                    {
                        tokensProject.length <= 1 ? 
                            <Alert
                                banner
                                message={
                                <Marquee pauseOnHover gradient={false}>
                                    Non hai ancora creato alcun token per poter utilizzare le api in modo pubblico.
                                </Marquee>
                                }
                            />
                            : ""
                    }
                    <div className='w-full '>
                        <div className='w-full flex items-center mx-3 my-3'>
                            
                            <Input placeholder="Endpoint Api Lpayground" value={process.env.REACT_APP_END_POINT_API} disabled className=' !px-2 !cursor-text mr-3' />
                            
                            <Tooltip placement="bottom" title={"Copia"}><Button type="primary" className='bg-indigo-500 hover:!bg-indigo-600 mr-3'><Copy size={17} /></Button></Tooltip>
                            <Tooltip placement="bottomLeft" title={"Prova Api"}><Button type="primary" shape="round" className='bg-indigo-600 hover:!bg-indigo-700 !p-2 !rounded-full mr-4' onClick={this.startClick} icon={<PlayCircleOutlined className='relative top-[-7px]' />}  /></Tooltip>
    
                        </div>
                        <div className='w-full flex mx-3'>
                            
                            <Select
                                defaultValue="default"
                                style={{  }}
                                onChange={this.keyApiground}
                                options={tokensProject}
                            />
                            
                        </div>
                    </div>
                    <div className=" overflow-x-hidden " id="codemir--23erf">
                        {/*<!-- Right column content -->*/}
                        <div className='flex-1 flex mt-4'>
                            <div className='w-auto flex-1'>
                                <CodeMirror
                                    value={JSON.stringify(code, undefined, 2).toString()}
                                    //value={"code"}
                                    height={codemirrorHeight}
                                    className=' border-b border-r border-t border-gray-300'
                                    theme={githubLight}
                                    onChange={this.changeCode}
                                    extensions={[loadLanguage('json')]} 
                                    readOnly={true}
                                    basicSetup={{
                                        foldGutter: false,
                                        dropCursor: false,
                                        allowMultipleSelections: false,
                                        indentOnInput: false,
                                        tabSize: 4
                                    }}
                                />
                            </div>
                            <div className='w-auto flex-1 overflow-auto scrollbar relative'>
                                <CodeMirror
                                    value={JSON.stringify(result, undefined, 2).toString()}
                                    className='h-full border-b border-t border-gray-300'
                                    height={codemirrorHeight}
                                    theme={githubLight}
                                    extensions={[loadLanguage('json')]} 
                                    basicSetup={{
                                        foldGutter: false,
                                        dropCursor: false,
                                        allowMultipleSelections: false,
                                        indentOnInput: false,lineNumbers: false,
                                        tabSize: 4
                                    }}
                                    readOnly={true}
                                />
                                {codeLoading ? <div className='absolute top-0 w-full h-full bg-gray-900/5 flex items-center justify-center'>
                                    <LoadingOutlined />
                                </div> : ""}
                            </div>
                        </div>
                        <div className=''>
                            <div className=''>
                                <div className='bg-gray-50 w-full h-7 flex items-center px-2'>HTTP HEADERS</div>
                                <CodeMirror
                                    value={JSON.stringify(codeHeaders, undefined, 2)}
                                    height={codemirrorHeight}
                                    //width={document.body.offsetWidth + "px"}
                                    theme={githubLight}
                                    extensions={[loadLanguage('json')]}
                                    autoResize={false}
                                    basicSetup={{
                                        foldGutter: false,
                                        dropCursor: false,
                                        allowMultipleSelections: false,
                                        indentOnInput: false,
                                        tabSize: 2,
                                        autoResize: false
                                    }}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}


export default withRouter(Playground);